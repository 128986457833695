import React, { useState, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";

import { AccountContext } from "context/AccountContext";
import DebugService from "services/DebugService";

const DebugWrapper = styled.div`
  position: fixed;

  text-align: right;

  bottom: 10px;
  right: 10px;
  z-index: 9999;

  background-color: white;

  ${({ open }) =>
    open &&
    `
    border-top: 1px solid black;
    border-left: 1px solid black;
  `}
`;
const DebugPanel = styled.div`
  & > table {
    margin: 0;
    width: 100%;
  }
`;
const Tabs = styled.div`
  display: flex;
`;
const Tab = styled.button`
  ${({ active }) => active && `font-weight: bold;`}
`;

const MenuButton = styled.button`
  margin-top: 5px;
`;

const EditSubscriptionRow = ({
  name,
  inputType = "text",
  options,
  subscription,
  onChange,
  hideUpdate = false,
}) => {
  const mutateValue = (value, fromInput = false) => {
    if (inputType === "datetime-local" && value) {
      // JS date handling sucks
      if (fromInput) {
        value += ".000Z";
      }

      const date = new Date(value);
      value = date.toJSON();
    }
    return value || "";
  };
  const formatValue = (value) => {
    if (inputType === "datetime-local" && value) {
      const date = new Date(value);
      value = date.toJSON();
      value = value.substr(0, value.length - 1);
    }
    return value;
  };

  const [value, setValue] = useState("");

  const handleChange = ({ target: { value } }) => {
    setValue(mutateValue(value, true));
  };

  const handleUpdateSubscription = (e) => {
    DebugService.changeSubscription({ ...subscription, [name]: value });
  };

  const handleRevert = (e) => {
    setValue(mutateValue(subscription[name]));
  };

  useEffect(() => {
    onChange && onChange({ target: { name, value } });
  }, [onChange, value]);

  const mount = () => {
    setValue(mutateValue(subscription[name]));
  };
  useEffect(mount, []);

  return (
    <tr>
      <th>{name}:</th>
      <td>
        {(() => {
          switch (inputType) {
            case "dropdown":
              return (
                <select
                  style={{ width: "100%" }}
                  defaultValue={value}
                  name={name}
                  onChange={handleChange}
                >
                  {options.map((v, i) => (
                    <option value={v} key={i}>
                      {v}
                    </option>
                  ))}
                </select>
              );
            default:
              return (
                <input
                  style={{ width: "100%" }}
                  type={inputType}
                  name={name}
                  value={formatValue(value)}
                  onChange={handleChange}
                />
              );
          }
        })()}
      </td>
      {!hideUpdate && (
        <td>
          <button onClick={handleUpdateSubscription}>Update</button>
        </td>
      )}
      <td>
        <button onClick={handleRevert}>Revert</button>
      </td>
    </tr>
  );
};

const DebugMenu = (props) => {
  const { subscription, profile } = useContext(AccountContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const openMenu = () => {
    setMenuOpen((orig) => !orig);
  };

  const profileBalance = profile?.balance || 0;

  const [subscriptionState, setSubscriptionState] = useState(subscription);
  const [balanceState, setBalanceState] = useState({
    balance: profileBalance || 0,
    dirty: false,
  });

  const handleSubscriptionChange = useCallback(
    ({ target: { name, value } }) => {
      setSubscriptionState((orig) => ({ ...orig, [name]: value }));
    },
    [setSubscriptionState]
  );

  const handleBalanceChange = useCallback(
    ({ target: { value } }) => setBalanceState({ balance: value, dirty: true }),
    [setBalanceState]
  );
  const revertBalance = useCallback(() =>
    setBalanceState({ balance: profileBalance || 0, dirty: false })
  );

  const handleUpdateSubscription = (e) => {
    DebugService.changeSubscription(subscriptionState);
  };

  const handleUpdateBalance = (e) => {
    DebugService.changeBalance(balanceState.balance);
    setBalanceState(({ balance }) => ({ balance, dirty: false }));
  };

  useEffect(() => !balanceState.dirty && revertBalance(), [profileBalance]);

  const handleDelete = (e) => {
    if (
      window.confirm(
        "Are you sure? This can't be undone. Page will refresh on completion."
      )
    ) {
      DebugService.deleteSubscription().then(() => window.location.reload());
    }
  };

  const tabs = {
    "View Subscription": subscription ? (
      <table>
        <tbody>
          {Object.keys(subscription).map((key, i) => (
            <tr key={i}>
              <th>{key}:</th>
              <td style={{ textAlign: "left" }}>
                {typeof subscription[key] === "object"
                  ? JSON.stringify(subscription[key])
                  : subscription[key]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div>No subscription</div>
    ),
    "View Profile": profile ? (
      <table>
        <tbody>
          {Object.keys(profile).map((key, i) => (
            <tr key={i}>
              <th>{key}:</th>
              <td style={{ textAlign: "left" }}>
                {typeof profile[key] === "object"
                  ? JSON.stringify(profile[key])
                  : profile[key]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div>Loading</div>
    ),
    "Edit Subscription": subscription ? (
      <React.Fragment>
        <table>
          <tbody>
            <EditSubscriptionRow
              name='students'
              inputType='number'
              subscription={subscription}
              onChange={handleSubscriptionChange}
              hideUpdate={true}
            />
            <EditSubscriptionRow
              name='duration'
              inputType='number'
              subscription={subscription}
              onChange={handleSubscriptionChange}
              hideUpdate={true}
            />
            <EditSubscriptionRow
              name='status'
              inputType='dropdown'
              options={[
                "active",
                "pending_payment",
                "failed",
                "failed_declined",
              ]}
              subscription={subscription}
              onChange={handleSubscriptionChange}
              hideUpdate={true}
            />
            <EditSubscriptionRow
              name='starts_at'
              inputType='datetime-local'
              subscription={subscription}
              onChange={handleSubscriptionChange}
              hideUpdate={true}
            />
            <EditSubscriptionRow
              name='expires_at'
              inputType='datetime-local'
              subscription={subscription}
              onChange={handleSubscriptionChange}
              hideUpdate={true}
            />
          </tbody>
        </table>
        <div style={{ textAlign: "center" }}>
          <button onClick={handleUpdateSubscription}>Update</button>
          <button style={{ color: "red" }} onClick={handleDelete}>
            Delete
          </button>
        </div>
      </React.Fragment>
    ) : (
      <div>No Subscription</div>
    ),
    "Edit Balance": (
      <React.Fragment>
        <div style={{ marginTop: "7px", marginBottom: "7px" }}>
          <div>Values are in cents (no decimals)</div>
          <div>Negative values represent credit</div>
          <div>Positive values represent debt</div>
        </div>
        <label style={{ fontWeight: "bold" }}>
          Balance:&nbsp;
          <input
            type='number'
            name='balance'
            value={balanceState.balance}
            onChange={handleBalanceChange}
          />
        </label>
        <div style={{ textAlign: "right" }}>
          <button onClick={revertBalance}>Revert</button>
          <button onClick={handleUpdateBalance}>Update</button>
        </div>
      </React.Fragment>
    ),
  };
  const [currentTab, setCurrentTab] = useState(Object.keys(tabs)[0]);

  return (
    <DebugWrapper open={isMenuOpen}>
      {isMenuOpen && (
        <DebugPanel>
          <Tabs>
            {Object.keys(tabs).map((key, i) => (
              <Tab
                key={i}
                active={currentTab === key}
                onClick={() => setCurrentTab(key)}
              >
                {key}
              </Tab>
            ))}
          </Tabs>
          {tabs[currentTab]}
        </DebugPanel>
      )}
      <MenuButton onClick={openMenu}>&lt;&lt; Debug &gt;&gt;</MenuButton>
    </DebugWrapper>
  );
};

export default DebugMenu;
