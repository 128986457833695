import React from "react";
import styled from "styled-components";

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: center;

  background-color: ${({ chosenColor }) => chosenColor};
  background-image: ${({ chosenIcon }) => `url(${chosenIcon})`};
  border: ${({ border, chosenColor }) => border || `2px solid ${chosenColor}`};
  box-shadow: ${({ boxShadow }) => boxShadow};
  opacity: ${({ opacity }) => opacity || 1};
  background-size: ${(backgroundSize) => backgroundSize || "80%"};

  &::before {
    content: "";
    width: 100%;
    padding-bottom: 100%;

    top: 0;
    left: 0;
  }
`;

const AvatarContents = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Label = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 15px;
`;

class Avatar extends React.Component {
  render() {
    const {
      children,
      label,
      contentsStyle,
      labelStyle,
      showPlus,
      color,
      icon,
      border,
      boxShadow,
      opacity,
      backgroundSize,
      jsonData,
      ..._props
    } = this.props;

    let chosenColor = color;
    let chosenIcon = icon;
    if (jsonData) {
      let parsedJsonData = null;
      try {
        parsedJsonData = JSON.parse(jsonData);
      } catch (e) {
        console.error("Avatar JSON parsing failed", e.message);
      }

      chosenColor = parsedJsonData.color;
      chosenIcon = parsedJsonData.icon;
    }

    return (
      <AvatarWrapper
        style={{
          backgroundColor: chosenColor,
          backgroundImage: `url(${chosenIcon})`,
          border: border || `2px solid ${chosenColor}`,
          boxShadow,
          opacity,
          backgroundSize: backgroundSize || "80%",
        }}
        {..._props}
      >
        {children && (
          <AvatarContents style={contentsStyle}>{children}</AvatarContents>
        )}
        {showPlus && <AvatarContents style={contentsStyle}>+</AvatarContents>}
        {label && <Label style={labelStyle}>{label}</Label>}
      </AvatarWrapper>
    );
  }
}

export default Avatar;
