import FS from "./FetchService";

export default class DebugService {
  static async changeSubscription(subscription) {
    const { students, duration, status, starts_at, expires_at } = subscription;
    return await FS.post(
      "/debug/subscription",
      JSON.stringify({ students, duration, status, starts_at, expires_at }),
      {
        header: (h) => h.append("Content-Type", "application/json"),
      }
    );
  }

  static async deleteSubscription() {
    return await FS.delete("/debug/subscription");
  }

  static async changeBalance(balance) {
    return await FS.post("/debug/balance", JSON.stringify({ balance }), {
      header: (h) => h.append("Content-Type", "application/json"),
    });
  }
}
