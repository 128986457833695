import React, { useEffect, useContext } from "react";

import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import PaymentForm from "./PaymentForm";
import { AccountContext } from "context/AccountContext";
import { ShopContext } from "context/ShopContext";

const PaymentSelector = (props) => {
  const { subscription } = useContext(AccountContext);

  const {
    options: { paymentTypes },
    selectedPaymentType,
    setPaymentType,
    isUpgrade,
  } = useContext(ShopContext);

  useEffect(() => {
    if (isUpgrade && subscription) {
      const paymentType = paymentTypes.find(
        (type) => type.name === subscription.payment_method
      );
      setPaymentType(paymentType);
    } else if (paymentTypes.length > 0) {
      setPaymentType(paymentTypes[0]);
    }
  }, [paymentTypes, isUpgrade, subscription, setPaymentType]);

  return (
    <React.Fragment>
      <em>Pay with </em>
      {isUpgrade || paymentTypes.length === 1
        ? selectedPaymentType && selectedPaymentType.label
        : paymentTypes.length > 1 &&
          selectedPaymentType && (
            <RadioGroup
              row
              style={{ display: "inline" }}
              name='type'
              value={selectedPaymentType.name}
              onChange={({ target: { value } }) => {
                const type = paymentTypes.find((type) => type.name === value);
                setPaymentType(type);
              }}
            >
              {paymentTypes.map((type, i) => (
                <FormControlLabel
                  key={i}
                  value={type.name}
                  control={<Radio color='primary' />}
                  label={type.label}
                />
              ))}
            </RadioGroup>
          )}
      {PaymentForm && <PaymentForm {...props} />}
    </React.Fragment>
  );
};

export default PaymentSelector;
