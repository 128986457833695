import React, { useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import Button from "components/Input/Button";

const DropdownArrowIcon = (props) => {
  const { expanded } = props;

  return (
    <ArrowDropDownIcon
      style={{ transform: `rotate(${expanded ? "180deg" : "0deg"})` }}
    />
  );
};

const OptionButton = (props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const { options = [] } = props;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const mainComponent = props.onClick ? (
    <ButtonGroup
      variant="contained"
      color="primary"
      ref={anchorRef}
      aria-label="split button"
    >
      <Button onClick={(e) => props.onClick(e)}>{props.children}</Button>
      <Button
        color="primary"
        size="small"
        aria-controls={open ? "split-button-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <DropdownArrowIcon expanded={open} />
      </Button>
    </ButtonGroup>
  ) : (
    <ButtonGroup
      variant="contained"
      color="primary"
      aria-controls={open ? "split-button-menu" : undefined}
      aria-expanded={open ? "true" : undefined}
      ref={anchorRef}
      aria-label="split button"
    >
      <Button
        endIcon={<DropdownArrowIcon expanded={open} />}
        onClick={handleToggle}
      >
        {props.children}
      </Button>
    </ButtonGroup>
  );

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        {mainComponent}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal={false}
          style={{ zIndex: "100" }}
          placement="bottom-end"
          popperOptions={{
            modifiers: {
              offset: { offset: "0, 8" },
            },
          }}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: "window",
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={(e) => option.onClick && option.onClick(e)}
                        {...option.attributes}
                      >
                        {option.icon && (
                          <ListItemIcon>{option.icon}</ListItemIcon>
                        )}
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default OptionButton;
