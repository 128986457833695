import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import PaymentIcon from "@material-ui/icons/Payment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ShopService from "services/ShopService";
import LoadingContainer from "components/Loading/LoadingContainer";
import { ShopContext } from "context/ShopContext";

const CardPanel = styled.div`
  width: 100%;
  margin: 14px 0px;
  padding: 16px 18px;
  border: 0.2px solid #a0a0a0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  user-select: none;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
`;

const CardOverview = (props) => {
  let { title } = props;

  const { selectedPaymentMethod } = useContext(ShopContext);

  const [isControlled] = useState(typeof selectedPaymentMethod !== "undefined");
  const [state, setState] = useState();

  useEffect(() => {
    if (!isControlled && typeof selectedPaymentMethod !== "undefined") {
      console.warn("Changing card overview from controlled to uncontrolled");
    }
  }, [selectedPaymentMethod, isControlled]);

  const loadDefaultPaymentMethod = async () => {
    setState({ loading: true });
    const defaultPaymentMethod = await ShopService.getDefaultPaymentMethod();
    setState({ paymentMethod: defaultPaymentMethod, loading: false });
  };

  useEffect(() => {
    if (!isControlled) {
      loadDefaultPaymentMethod();
    }
  }, [isControlled]);

  const paymentMethod = isControlled
    ? selectedPaymentMethod
    : state?.paymentMethod;

  if (!isControlled && state?.loading) {
    return <LoadingContainer />;
  }

  if (paymentMethod?.type !== "card") {
    return null;
  }

  const { card } = paymentMethod;

  return (
    <React.Fragment>
      {title ? title : null}
      <CardPanel>
        <Line>
          <PaymentIcon />
          &nbsp;
          <strong style={{ textTransform: "capitalize" }}>
            {card.brand}&nbsp;
          </strong>
          <em>{`**** **** **** ${card.last4}`}</em>
        </Line>
        <Line>
          <ScheduleIcon />
          &nbsp;
          <strong>Expires&nbsp;</strong>
          <em>{`${card.exp_month}/${card.exp_year}`}</em>
        </Line>
      </CardPanel>
    </React.Fragment>
  );
};

export default CardOverview;
