import FS from "./FetchService";

export default class ShopService extends FS {
  static currency = "usd";

  static getHeaders() {
    const headers = super.getHeaders();
    headers.append("X-Currency", this.currency);
    return headers;
  }

  static async getBundles() {
    return await this.get("/shop/bundles");
  }

  static async getUpgradePreview(product_id) {
    return await this.get(`/shop/bundles/preview_upgrade/${product_id}`);
  }

  static async getAvailablePaymentMethods() {
    return await this.get("/shop/pay");
  }

  static async getDefaultPaymentMethod() {
    return await this.get("/shop/default_payment_method");
  }

  static async makePurchase(bundle, payment) {
    return await this.post("/shop/pay", JSON.stringify({ bundle, payment }), {
      header: (h) => h.append("Content-Type", "application/json"),
    });
  }

  static async purchaseUpgrade(bundle, payment) {
    return await this.post(
      "/shop/upgrade",
      JSON.stringify({ bundle, payment }),
      {
        header: (h) => h.append("Content-Type", "application/json"),
      }
    );
  }

  static async applyCoupon(bundle, coupon, paymentType) {
    return await this.post(
      "/shop/preview_coupon",
      JSON.stringify({ bundle, coupon, payment: { type: paymentType } }),
      {
        header: (h) => h.append("Content-Type", "application/json"),
      }
    );
  }

  static async getReceipt(order_id) {
    return await this.get(`/receipts/${order_id}`);
  }

  // Stripe
  static async getStripeKey() {
    return await this.get("/shop/stripe/pub_key");
  }

  static async getStripeSetupSecret() {
    return await this.get("/shop/stripe/setup");
  }

  static async updatePaymentMethod(payment_info, update_subscription = false) {
    return await this.patch(
      "/shop/payment_methods/update_default",
      JSON.stringify({
        payment: { info: payment_info },
        update_subscription,
        _method: "PATCH",
      }),
      {
        header: (h) => h.append("Content-Type", "application/json"),
      }
    );
  }

  static async getStripePaymentSecret() {
    return await this.get(`/shop/stripe/latest_payment_intent`);
  }

  static async getMyStripePaymentMethods() {
    return await this.get("/shop/stripe/payment_methods");
  }

  static async cancelStripePlan() {
    return await this.post("/shop/cancel");
  }

  static async renewStripePlan() {
    return await this.post("/shop/renew");
  }
}
