import React, { useContext } from "react";
import styled from "styled-components";

import { H3 } from "components/LoginView/Headings";
import { AppContext } from "context/AppContext";
import { AccountContext } from "context/AccountContext";
import { ShopContext } from "context/ShopContext";
import { useLocation } from "react-router-dom";

const PriceTable = styled.table`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  border-collapse: collapse;
`;

const TR = styled.tr`
  ${({ lineBreak }) =>
    lineBreak &&
    `
    border-top: 1px solid grey;
  `}
`;

const PriceWarning = styled.div`
  position: relative;

  font-size: 12px;
  margin-bottom: 8px;

  padding-left: 10px;

  &:before {
    content: "*";
    position: absolute;
    left: 0;
  }
`;

const OrderOverview = (props) => {
  const { formatPrice } = useContext(AppContext);
  const { subscription } = useContext(AccountContext);

  const { currentOrder, bundlePreview, isUpgrade, isReceipt } =
    useContext(ShopContext);

  const order = (() => {
    if (isUpgrade) {
      return bundlePreview;
    } else if (currentOrder) {
      return currentOrder;
    } else {
      return {};
    }
  })();

  const {
    price,
    additional_line_items,
    starting_balance,
    ending_balance,
    next_billing_date,
    total,
  } = order || {};
  const isRenewal =
    !isReceipt &&
    subscription?.status === "active" &&
    subscription?.product_id === currentOrder?.product_id;

  const finalTotal = typeof total === "undefined" ? price : total;

  return (
    <React.Fragment>
      {isUpgrade && !isRenewal && (
        <React.Fragment>
          <H3 style={{ textAlign: "left" }}>What happens next</H3>
          <ul>
            <li>Your new plan starts today.</li>
            <li>
              {subscription?.renewal_type === "m"
                ? `Your subscription will be active until ${next_billing_date}.`
                : `Starting ${next_billing_date}, you will be charged ${formatPrice(
                    price
                  )}.`}
            </li>
            <li>
              We credit the value of your current plan to Your Credit. Your
              Credit is used for future payments.
            </li>
          </ul>
        </React.Fragment>
      )}
      <H3 style={{ textAlign: "left" }}>Order Summary</H3>
      <PriceTable>
        <tbody>
          <TR>
            <td>
              {isReceipt
                ? "Your Plan"
                : isRenewal
                ? "Renew your plan"
                : "Your new plan"}
            </td>
            <td style={{ textAlign: "right" }}>{formatPrice(price)}</td>
          </TR>
          {!!additional_line_items &&
            additional_line_items.map((line_item, i) => (
              <TR
                key={i}
                style={{
                  color: line_item.value.amount >= 0 ? "black" : "green",
                }}
              >
                <td>{line_item.name}</td>
                <td style={{ textAlign: "right" }}>
                  {formatPrice(line_item.value)}
                </td>
              </TR>
            ))}
          <TR lineBreak />
          <TR>
            <td>TOTAL</td>
            <td style={{ textAlign: "right" }}>{formatPrice(finalTotal)}</td>
          </TR>
          {(typeof starting_balance !== "undefined" ||
            typeof ending_balance !== "undefined") && (
            <TR style={{ height: "12px" }} />
          )}
          {typeof starting_balance !== "undefined" && (
            <TR
              style={{
                color: starting_balance?.amount >= 0 ? "black" : "green",
              }}
            >
              <td>Starting Balance</td>
              <td
                style={{
                  textAlign: "right",
                }}
              >
                {formatPrice(starting_balance)}
              </td>
            </TR>
          )}
          {typeof ending_balance !== "undefined" && (
            <TR
              style={{
                color: ending_balance?.amount >= 0 ? "black" : "green",
              }}
            >
              <td>Ending Balance</td>
              <td style={{ textAlign: "right" }}>
                {formatPrice(ending_balance)}
              </td>
            </TR>
          )}
        </tbody>
      </PriceTable>
      {finalTotal?.amount > 0 && finalTotal?.amount <= 50 ? (
        <PriceWarning>
          Totals less than USD $0.50 are not charged until the next payment
          date.
        </PriceWarning>
      ) : null}
    </React.Fragment>
  );
};

export default OrderOverview;
