import React, { useContext } from "react";

import { H2, H3 } from "components/LoginView/Headings";
import FormHelperText from "@material-ui/core/FormHelperText";

import LoadingContainer from "components/Loading/LoadingContainer";
import OrderOverview from "components/ShopView/OrderOverview";
import PaymentSelector from "../../components/ShopView/PaymentSelector";
import { ShopContext } from "context/ShopContext";

const CheckoutView = (props) => {
  const {
    options: { paymentTypes },
    selectedPaymentType,
    bundlePreview,
    isUpgrade,
    errors: { checkout: errors },
  } = useContext(ShopContext);

  const loading =
    !paymentTypes ||
    (isUpgrade && (bundlePreview === null || selectedPaymentType === null));

  return (
    <React.Fragment>
      {loading && <LoadingContainer />}
      <H2>Payment Info</H2>
      <OrderOverview />
      <H3 style={{ textAlign: "left" }}>Billing information</H3>
      <PaymentSelector {...props} name='payment' />
      {errors?.message && (
        <FormHelperText style={{ color: "red" }}>
          {errors.message}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};

export default CheckoutView;
