import React from "react";
import styled from "styled-components";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Button from "components/Input/Button";

const DefinitionBox = styled.table`
  background-color: lightgrey;
  padding: 7px;
  margin: 16px 5px;
`;

const TermsAndConditionsDialog = (props) => {
  const { onAccept, onClose, open, clickAway = false } = props;

  return (
    <Dialog open={open} onClose={onClose} disableBackdropClick={!clickAway}>
      <DialogTitle disableTypography>
        <h1>Terms &amp; Conditions</h1>
      </DialogTitle>
      <DialogContent>
        <p>
          Learning for Humanity (L4H) provides leading-edge elearning
          platforms.&nbsp; We ask that all users of our services respect some
          simple, common-sense terms and conditions (T&amp;C) of use. Our
          intention with our T&amp;C is to enable all subscribers an
          equiDefinitionBox opportunity to obtainthe highest quality education
          that competes on a local and global scale.
        </p>
        <p>
          Each section of these Terms &amp; Conditions (T&amp;C) begins with a
          gray box stating, in plain English, the meaning of the section. Below
          the gray box the full T&amp;C will be stated in legally-appropriate
          language. While the gray boxes are meant to aid comprehension, they
          are not a replacement for the full legal text of these T&amp;C, and
          only the full legal text will be considered in any arbitration or
          other proceeding.
        </p>
        <h2>1. Definitions</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  Whenever the T&amp;C say &lsquo;we&rsquo; they mean Learning
                  for Humanity (L4H). &lsquo;You&rsquo; means you, or more
                  broadly, anyone using our services. &lsquo;Services&rsquo;
                  refers to anything L4H provides as part of its products and
                  services. Content includes anything you access through an L4H
                  service.
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>For purposes of these T&amp;C, the following definitions apply.</p>
        <ul>
          <li>
            WE or US refers to Learning for Humanity and all subordinate
            organizations, including any affiliates insofar as they operate
            under agreement with Learning for Humanity.
          </li>
          <li>
            YOU refers to the registered user(s) and/or account-holder(s), as
            applicable, for any Learning for Humanity service.
          </li>
          <li>
            USER or USERS also refers to the registered user(s) and/or
            account-holder(s), as applicable, for any Learning for Humanity
            service.
          </li>
          <li>SERVICE refers to the Tusitawi Cloud Learning Platform.</li>
          <li>TUSITAWI refers to the Tusitawi Cloud Learning Platform.</li>
          <li>
            CONTENT refers to any materials and/or media, in any medium or file
            format, which is made available on Tusitawi Cloud Learning Platform
            whether open-access or licensed.
          </li>
          <li>
            TERMS &amp; CONDITIONS refers to the full content of this document;
            this document may also be referenced as TERMS. Learning for
            Humanity&rsquo;s Privacy Policy is also considered part of this
            document.
          </li>
        </ul>
        <h2>2. Applicable Law</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  Learning for Humanity is a Canadian company and, while
                  we&rsquo;ll try to respect the law wherever we operate, we
                  legally have to respect Canadian law first. And, because
                  we&rsquo;re a Canadian company, if you want to take any kind
                  of legal action against us, you have to do so in a Canadian
                  court.
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          We make every reasonable effort to adhere to all applicable law in
          operation of our services. However Learning for Humanity is a Canadian
          company and is itself bound by Canadian law. Therefore these Terms
          &amp; Conditions are governed, constrained, and applied under the law
          of the province of Ontario.
        </p>
        <p>
          In the event of a dispute, disagreement, or legal action between you
          and Learning for Humanity, you agree that all claims and adjudication
          shall be governed by the law of Ontario in preference to that of any
          other jurisdiction. If you wish to file any sort of motion, claim,
          injunction, or other action against us, you agree to file and resolve
          it entirely in a court located in Ontario, Canada.
        </p>
        <h2>3. Changes to the Terms &amp; Conditions</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  We change our Terms &amp; Conditions from time to time to make
                  them work better for everyone. When we do change them,
                  we&rsquo;ll tell you first, and we&rsquo;ll give you a
                  month&rsquo;s notice for a major change. If we happen to
                  change them in the middle of a legal dispute, the changes
                  won&rsquo;t count for resolving the dispute one way or the
                  other.
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          From time to time, Learning for Humanity may change or update these
          Terms &amp; Conditions. We do this both to improve the Terms &amp;
          Conditions, and to remain in compliance with the laws and regulations
          of the different countries where we operate. If we do change these
          Terms &amp; Conditions, we will make you aware that we have done so,
          and provide you with a clear summary of what we have changed, why, and
          what the changes mean for you.
        </p>
        <p>
          If at the time we implement any change to these Terms &amp;
          Conditions, you are involved in an ongoing arbitration, legal action,
          or other proceeding under these Terms &amp; Conditions, any changes to
          these Terms &amp; Conditions which occurred following the incident
          which is the subject of the ongoing proceeding will not be considered
          as valid when the result of the proceeding is adjudicated.
        </p>
        <p>
          If Learning for Humanity is involved in a merger, acquisition or asset
          sale, such that these Terms &amp; Conditions become null and void and
          are replaced with new Terms &amp; Conditions at the discretion of the
          new controlling parties, we will both notify you, and provide you with
          a copy of the new Terms &amp; Conditions, 30 calendar days in advance
          of the change.
        </p>
        <h2>4. Provision of Services</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  You can use Tusitawi in these ways: i) by yourself as an
                  independent learner, ii) as a member of a group such as a
                  homeschooling group or a private school, or iii) as the
                  administrator of a group (families work like
                  &lsquo;groups&rsquo;, with one parent being the administrator
                  and the children being group members). This outlines the
                  arrangement between L4H and each type of user, including what
                  we promise, and what we ask in terms of payment:
                  <ul>
                    <li>
                      Everyone is allowed to use Tusitawi Content as assigned to
                      each person&rsquo;s classes.
                    </li>
                    <li>
                      There is a fee per student for access and for content
                      delivery
                    </li>
                    <li>
                      The price you pay for Tusitawi won&rsquo;t change after
                      the fact, as long as you don&rsquo;t let your account
                      lapse
                    </li>
                    <li>
                      The administrator of a group can purchase access to
                      additional content on a monthly basis. An independent
                      learner can do the same thing on his or her own behalf
                    </li>
                    <li>
                      You can&rsquo;t share your Tusitawi account with another
                      person. If someone else wants to use Tusitawi, they must
                      subscribe independently
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          Access to our services is granted to specific individuals. Access to
          our services may be purchased either singly or on behalf of a group.
          &lsquo;Group&rsquo; is understood to mean any number of individuals
          acting together for purposes of access to Tusitawi. Groups may be
          formal or informal. As such, a group for purposes of these Terms &amp;
          Conditions can include any public or private school, corporate body,
          homeschooling group, family, neighborhood association, or any other
          body, including groups formed for the sole purpose of purchasing
          collective access to Tusitawi.
        </p>
        <p>
          In the event of a group purchasing access to our services, the
          personal account under which the initial purchase is made is
          understood to be the master account of the group in question. Only the
          master account of a group is permitted to purchase and allocate access
          periods, additional content, or other services for the members of the
          group in question. The individual responsible for managing the master
          account is known as the group administrator. We do not monitor,
          appoint, or accept any responsibility for the conduct of any group
          administrator except as such conduct is found to be in violation of
          these Terms &amp; Conditions.
        </p>
        <p>
          The submission of personal information and payment details to us
          through our online store or through the Tusitawi payment utility shall
          constitute a contract for the delivery of services between Learning
          For Humanity and the person named in the information submitted. The
          length of this contract shall be the length of time specified through
          our online store or through the Tusitawi payment utility.
        </p>
        <p>
          Where a group purchases access to our services, the above contract
          shall instead be regarded as between Learning For Humanity and the
          group as a corporate body. If the group is not chartered,
          incorporated, or does not otherwise exist as a legal entity, the above
          contract shall instead be regarded as between Learning for Humanity
          and each individual named as part of the group during the purchasing
          process. If, prior to the expiry of the aforesaid contract, the period
          of access is extended through the purchase of additional periods, this
          shall constitute an extension of the contract created upon the initial
          purchase. You affirm that, by making such a purchase, you agree to the
          extension of the contract between you and Learning for Humanity.
          Learning for Humanity likewise affirms that we agree to extension of
          the contract under the aforesaid circumstances.
        </p>
        <p>
          Our fee structure is subject to change based on the immediate
          circumstances of the client and of Learning For Humanity. The fees
          specified in the online store or the Tusitawi payment utility are
          considered fixed for the duration of the contract created upon
          purchase. Learning For Humanity will not adjust fees or impose
          additional charges during the period of a contract, except for the
          purchase of additional content as specified below. In particular, as
          long as the contract between Learning for Humanity and the client
          created at the time of that client&rsquo;s initial purchase of
          services from Learning for Humanity, is extended by the purchase of
          additional access periods as outlined above, the initial fees paid per
          period of access shall not be changed by Learning for Humanity.
        </p>
        <p>
          In all cases, access to our services is purchased for the exclusive
          use of the person(s) named in the above contract. Each individual
          named in the above contract, or if the contract is between Learning
          for Humanity and a corporate group, each member of the group, will be
          permitted to create an individual Tusitawi user account. Only the
          specific person by whom a user account is created is permitted to
          access Learning for Humanity services through that user account.
          Learning for Humanity may revoke or suspend access to a user account
          in the event that account is used by multiple persons to access our
          services.
        </p>
        <p>
          Each individual&rsquo;s access to our services includes a selection of
          content, curated by Learning For Humanity, to meet the specific
          educational needs of that individual, as determined by each
          individual&rsquo;s grade level and educational focus. We make every
          reasonable effort to ensure that our content meets all applicable
          standards for quality and scope of education, as established by the
          ministries of education, school boards, and other educational
          officials in each country where we operate.
        </p>
        <p>
          Access to additional content may be requested as desired by an
          individual or by the administrator of a group. Access to additional
          content may be subject to additional terms or fees as determined by
          Learning for Humanity and/or by the rightsholder of the content in
          question. In all cases these additional terms and fees will be clearly
          presented before content is purchased. In particular we require that
          all users understand and respect any licensing conditions which may
          apply to additional content.
        </p>
        <h2>5. Account Usage</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  A Tusitawi account includes access only for the specific
                  people named when the account is registered. No one else is
                  allowed to use your account, and we can terminate your account
                  (either for a time, or permanently) if you do. If you&rsquo;re
                  a group administrator, it is your responsibility to ensure
                  that no-one else is using any of the accounts you&rsquo;re
                  responsible for. If you don&rsquo;t, we have the option to
                  shut down all the accounts in your group.
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          A registered account is required in order to use any of our services.
          When an account is registered, a specific individual will be named as
          the owner of that account as outlined above under Provision of
          Services. Only the user named as the owner of a Tusitawi account is
          permitted to use that account to access our services. Each user agrees
          not to share information regarding the password and other security
          features of that user&rsquo;s account, and agrees not to permit any
          other person to access our services through that user&rsquo;s unique
          account.
        </p>
        <p>
          If you are the group administrator as outlined above under Provision
          of Services, you accept responsibility for ensuring that each
          user&rsquo;s account is accessed only by the account owner. Failure to
          limit access to an account to only the owner of that account shall
          constitute breach of contract, and is grounds for suspension or
          termination of the account, without refund of any payments made.
          Failure by an administrator to limit access to the account(s) under
          that person&rsquo;s supervision to only the owner of those account(s)
          shall constitute breach of contract, and is grounds for suspension or
          termination of all accounts administered by the aforesaid
          administrator, without refund of any payments made.
        </p>
        <h2>6. Potentially-Offensive Content</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  L4H invests time and money, and employs nationals in each
                  country, to respect the culture and beliefs of all our users,
                  but we can not guarantee that,&nbsp; from user to user, there
                  may be content that you disagree with, especially if you
                  request additional content from outside the standard
                  educational resources used in your educational system. If
                  something you or your children see on our services offends you
                  for any reason, we&rsquo;re sorry for that and we&rsquo;ll
                  block the content if you want us to, but you are accepting the
                  risk of that happening when you sign up for Tusitawi, and
                  there is no refund for services, nor ability for you to take
                  legal action against us because of it.
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          We make every effort to ensure that the content we supply using our
          services is curated with respect to the age, culture, religious
          affiliation, and other considerations regarding the intended users of
          that content. However we also recognize that taste and tolerance may
          vary from person to person within an age, cultural or religious group.
          You acknowledge that, in the course of using our services, you or your
          child or children may encounter material which you consider to be
          objectionable, false, or offensive. You agree to accept sole and
          entire liability for any loss or injury of any kind, whether physical,
          emotional, or any other form of loss or injury, to yourself, your
          child or children, or any third party, which arises from the material
          in any content accessed through our services.
        </p>
        <p>
          You likewise acknowledge that, in the event that you are responsible
          for administering a Tusitawi Cloud Learning System account on behalf
          of other users, and in doing so you request or purchase on your own
          initiative additional content from us, some or all of that content may
          be regarded, either by the users to whom you allow access to the
          additional content, or by members of those users&rsquo; families,
          communities, or religious institutions, as objectionable, false, or
          offensive.
        </p>
        <p>
          In the event that any content provided through our services is
          regarded as objectionable, false, or offensive, by you, any other
          registered user, or any third party, we accept no liability for any
          damages or claims which may arise therefrom.
        </p>
        <p>
          Within the requirements of applicable law, we will block or remove
          access to content which has been found offensive, upon your request.
          If you request that we place such a block upon purchased content for
          reasons of offense, we will not issue any refund, reimbursement, or
          any other form of compensation for the period of access which you are
          currently using at the time of the issuance of the request.&nbsp;
        </p>
        <p>
          <strong>7. Conduct</strong>
        </p>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  <ol>
                    <li>Don&rsquo;t be cruel to others in word or deed</li>
                    <li>
                      Don&rsquo;t pretend to be someone you&rsquo;re not, or
                      falsely claim to be part of a group to which you
                      don&rsquo;t belong
                    </li>
                    <li>
                      Don&rsquo;t use our services to do anything illegal
                      including, but not limited to, posting or viewing any kind
                      of content considered obscene or illegal where you live
                    </li>
                    <li>
                      Don&rsquo;t use our services to spy on people, send them
                      materials they don&rsquo;t want, sell product or services,
                      cheat people, or expose people&rsquo;s personal
                      information
                    </li>
                    <li>
                      If anyone breaks this code of conduct, we&rsquo;re not
                      responsible for their actions, although we <em>will</em>{" "}
                      ban them if warranted, and tell the police if what they
                      did was illegal
                    </li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          We ask that all our users conduct themselves in a respectful and civil
          manner. We require that all our users adhere to the following
          standards of conduct during any use of our services.
        </p>
        <ul>
          <li>
            Do not harass or abuse other users in any form which limits,
            disrupts, or discourages their use of our services, or which is
            discriminatory in any manner.
          </li>
          <li>
            Do not use our services to transmit, distribute, or cause to be
            transmitted or distributed any material which endorses or encourages
            any form of discrimination against any person by virtue of
            ethnicity, cultural background, religion, gender identity, sexual
            orientation, personal belief, mode of employment, education level,
            or any other personal characteristic.
          </li>
          <li>
            Do not use our services to transmit, distribute, or cause to be
            transmitted or distributed unwanted solicitations, or advertising,
            or chain mail, or other forms of unwanted and unwelcome
            communications.
          </li>
          <li>
            Do not infringe upon the privacy rights of others, which includes
            but is not limited to soliciting or supplying personal information
            regarding any other person, and soliciting or supplying personal
            information regarding any person under the age of majority as
            defined by applicable law in that person&rsquo;s place of residence,
            unless written consent is obtained to do so.
          </li>
          <li>
            Do not use our services to impersonate another person, or to
            misrepresent your or another person&rsquo;s affiliation with any
            person, agency, or entity.
          </li>
          <li>
            Do not use our services to intentionally and knowingly transmit,
            distribute, or cause to be transmitted or distributed any
            information or communication whose principal intent is to defraud,
            impersonate, or otherwise deceive.
          </li>
          <li>
            Do not use our services to intentionally and knowingly transmit,
            distribute, or cause to be transmitted or distributed any malicious
            software or other code, including but not limited to viruses,
            malware, adware, spyware, ransomware, worms, or Trojan horses.
          </li>
          <li>
            Do not use our services to facilitate, engage in, or otherwise
            perform or aid in performing any form of act which is illegal under
            applicable law.
          </li>
          <li>
            Do not use our services to copy, download or otherwise distribute,
            or to facilitate or otherwise aid in copying, downloading or
            otherwise distributing any material for which you are not the legal
            copyright holder, or for which you have not been granted the right
            to distribute by the legal copyright holder.
          </li>
          <li>
            Do not use our services to transmit, distribute, or cause to be
            transmitted or distributed any material which is considered illegal
            or obscene under applicable law.
          </li>
        </ul>
        <p>
          If you are found in violation this code of conduct, we reserve the
          right to respond by immediately and without notice terminating your
          use of our services, either permanently or for a period of time,
          without refund of any payments made, and to take any other measures
          which are merited by the violation and/or required of us by applicable
          law.
        </p>
        <p>
          Learning for Humanity is not responsible for, and accepts no liability
          for, the conduct of our users if such conduct is found in violation of
          this code of conduct or any other part of these Terms &amp;
          Conditions.
        </p>
        <h2>8. Handling of Personal Information</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  We will do our absolute best to keep any personal information
                  you give us secure.
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          We commit to secure, safe, and responsible handling of any personal
          information which you, either directly or indirectly, make available
          to us as a result of your use of our services. We will follow all
          procedures and conditions outlined in our Privacy Policy with respect
          to your personal information.
        </p>
        <p>
          You acknowledge that we may, as a result of our normal operations,
          transfer, store, or handle personal information you supply to us in a
          server or servers located elsewhere than your home country or current
          place of residence, and you grant us permission to do so.
        </p>
        <p>
          We make every effort to adhere at all times both to all applicable law
          and to our Privacy Policy regarding the handling, storage, transfer
          and security of personal information.&nbsp;
        </p>
        <p>
          <strong>9. Content-specific Permissions</strong>
        </p>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  Our Services display Content; Content you access on our
                  services has its own licensing restrictions on what you can
                  and cannot do with it. You must respect and adhere to those
                  licensing restrictions, otherwise you are liable for any
                  licensing violations.&nbsp;
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          Part of the services we provide is the aggregation and display of
          Content. Even in the case of Content which is made publicly-available
          under a Creative Commons (&lsquo;CC&rsquo;) license, the GNU Public
          License (&lsquo;GPL&rsquo;) or other open-access license, content
          available through our services remains the property of its legal
          rights holder, and is made available under conditions which may vary
          from item to item.
        </p>
        <p>
          Content we make available to you through the Tusitawi Cloud Learning
          System is always licensed for personal, educational use. If you
          purchase access to additional content through our Services, whether
          the purchase is made by you directly or by a third party acting on
          your behalf (as outlined above under Provision of Services), you are
          not granted any permissions other than personal, educational use
          except as any license specific to the purchased content grants you
          such permission. We require that any use you make of content provided
          through our services above and beyond the specific purposes for which
          the content is made available to you is done with complete adherence
          to the specific permissions granted or withheld by the license
          specific to the content in question.
        </p>
        <p>
          In particular, before reproducing, downloading, or copying any content
          from our services in any way, you must first confirm that the rights
          holder has granted permission for the use you intend to make of the
          content in question.
        </p>
        <p>
          By reproducing, downloading, or copying any content from our services
          in any way, you affirm that you have confirmed that the rights holder
          has granted permission for the specific use you are making of the
          content, and accept full responsibility if you are later found in
          violation of the rights regarding use of that content.
        </p>
        <h2>10. Disclaimer of Reliability</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  You can learn a lot through our services. You can even get
                  access to medical, legal, and other information that&rsquo;s
                  kept as up-to-date as possible . But this information is not
                  intended to replace the services of a trained lawyer, doctor,
                  or other professional. No matter how much you&rsquo;ve learned
                  through our services, our services do not replace a lawyer,
                  doctor, or anything else. We are not responsible for any
                  actions you take using our Services.
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          While we make every effort to ensure that the content we provide
          through our services is up-to-date, accurate, and reliable, no part of
          our services is an accepDefinitionBox substitute for the services of
          professionals in the relevant fields. In the event you require
          medical, legal, psychiatric, or any other form of professional advice,
          counsel, or service, consult a professional in the relevant fields. We
          are not responsible for any loss or injury, to yourself or to any
          other person, agency, or entity, which may arise if you attempt to use
          content provided by our services to substitute for such professional
          assistance.
        </p>
        <p>
          We make no claim to any standing as a professional in any field of
          medical, legal or psychiatric practice. Content created by us, whether
          courseware, classes, related resources, or in any other form, does not
          and should not be understood to comprise professional advice. Our
          content is intended for educational use only, and we do not recommend,
          endorse or approve of any use of our content or services for any
          purpose other than to supplement formal education.
        </p>
        <h2>11. Non-Accreditation</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  Tusitawi is not an official school. While you can learn a lot
                  from Tusitawi, you can not earn an official degree through us.
                  Tusitawi is used to supplement your education in an
                  officially-recognized institution of learning. Using Tusitawi
                  alone doesn&rsquo;t give you the right to call yourself a
                  high-school graduate or college-educated, for example.
                  We&rsquo;re not responsible for anything that happens if you
                  do.
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          We hereby disclaim any association with or certification by any
          educational ministry, school board, elementary or secondary school,
          college, university, trade school, or any other institution of
          education or vocational training. Learning for Humanity has no power
          to grant a diploma, certificate, degree, or other form of
          accreditation to any person.
        </p>
        <p>
          You acknowledge and affirm that you will not claim any educational
          status, official certification, legal or vocational standing, or any
          other status or merit, if such a claim is based solely on your use of
          our services. If you falsely claim educational status in despite of
          these Terms and Conditions, we accept no liability for any claims or
          damages, to you or any third party, which may arise therefrom.
        </p>
        <h2>12. External Links</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  It&rsquo;s very unlikely, but possible, that some content
                  provided through our services will include links to websites
                  outside our elearning platform. We&rsquo;re not responsible
                  for the accuracy, content, morality, or quality of any content
                  found on these links as the content is outside our control. We
                  don&rsquo;t recommend any content outside our system.
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          While uncommon, it is not impossible for content accessed through one
          of our services, particularly applications, to include links to
          websites or other external resources not under the control of Learning
          for Humanity. You acknowledge that in the event you or your child or
          children accesses an external website through a Learning for Humanity
          service we are not responsible for the content, security or safety of
          such access. We do not review, curate, endorse, or otherwise control
          access to external content through our services, nor do we have any
          responsibility to do so. You agree that we cannot be held legally or
          contractually responsible for the value, permissions, or other
          circumstances arising if you or your child or children accesses
          content external to Tusitawi using the Tusitawi system.
        </p>
        <h2>13. Other Considerations</h2>
        <div>
          <DefinitionBox>
            <tbody>
              <tr>
                <td>
                  We can choose to let violations of these Terms &amp;
                  Conditions slide at our discretion. If we do, that
                  doesn&rsquo;t make the Terms &amp; Conditions as a whole
                  invalid, and we can choose to pursue the same violation if it
                  occurs again. The same applies to you; just because you choose
                  not to insist on a right the Terms &amp; Conditions gives you
                  doesn&rsquo;t mean you&rsquo;ve given up the right entirely.
                  If part of the Terms &amp; Conditions is invalid, it
                  doesn&rsquo;t negate the rest of them. These Terms &amp;
                  Conditions were written first in English, so that the English
                  version is considered authoritative if, for whatever reason,
                  there&rsquo;s a difference in meaning between the English
                  version and a version in another language.
                </td>
              </tr>
            </tbody>
          </DefinitionBox>
        </div>
        <p>
          We reserve the right to enforce these Terms &amp; Conditions, wholly
          and in part, as we see fit, giving due consideration to circumstance
          and to the requirements of all law applicable to Our operations. In
          particular, if we choose at any specific time to forego enforcing any
          part of these Terms &amp; Conditions, that non-enforcement shall not
          be construed as nullifying or suspending these Terms &amp; Conditions,
          either wholly or in part.
        </p>
        <p>
          In the same way, if in any specific circumstance you choose to forego
          insistence upon your rights as outlined in these Terms &amp;
          Conditions, that shall not be construed as a waiver or forfeiture of
          your rights as outlined in these Terms &amp; Conditions.
        </p>
        <p>
          If any provision or part of a provision of these Terms &amp;
          Conditions is found unlawful, void, or unenforceable, that provision
          or part of the provision is deemed severable from these Terms &amp;
          Conditions and will be enforced to the maximum extent permissible, and
          all other provisions of these Terms &amp; Conditions will remain in
          full force and effect.
        </p>
        <p>
          In the event of a disparity between the English version of these Terms
          &amp; Conditions and any other version, the English version shall take
          precedence.
        </p>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        {onAccept && (
          <Button color="primary" onClick={onAccept}>
            Accept
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
