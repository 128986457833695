import React, { useContext } from "react";
import { compose } from "recompose";

import withTheme from "composers/withTheme";

import ManageProfileDetailsView from "./ManageProfileDetailsView";
import ManageSubscriptionView from "./ManageSubscriptionView";
import ManagePasswordView from "./ManagePasswordView";
import { H2 } from "components/AccountView/Headings";

import { AppContext } from "context/AppContext";

const maxWidth = "680px";

const ManageProfile = (props) => {
  const { style } = useContext(AppContext);
  const { responsiveSize } = props;

  return (
    <React.Fragment>
      {responsiveSize !== "phone" && <H2>Account Settings</H2>}
      <ManageProfileDetailsView style={{ width: "100%", maxWidth }} />
      {responsiveSize === "phone" && <hr style={{ marginTop: "20px" }} />}
      {style === "subscription" && (
        <ManageSubscriptionView style={{ width: "100%", maxWidth }} />
      )}
      {responsiveSize === "phone" && <hr style={{ marginTop: "20px" }} />}
      <ManagePasswordView style={{ width: "100%", maxWidth }} />
    </React.Fragment>
  );
};

export default compose(withTheme)(ManageProfile);
