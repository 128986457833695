/**
 * Entry point of the entire application. Don't be too worried about how many things are going on here.
 *
 * The "REAL" entry point, or the "Application" itself is in `App.js`
 *
 * This file simply scaffolds all the global state objects (redux),
 * redux middle-wares (such as thunk which lets us create asynchronous redux actions),
 * and whichever other react plugins we want to use (like react-router).
 *
 * We can also import global css files from plugins, and our own css, though we use
 * styled-components which is a per-component based styling plugin.
 */

import "react-app-polyfill/stable";
// ^ https://github.com/facebook/create-react-app/issues/7242
import "url-search-params-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import "react-image-crop/dist/ReactCrop.css";
import { Provider, ReactReduxContext } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import {
  createMuiTheme,
  MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";

import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import App from "./App";
import createRootReducer from "./reducers";
import "./index.css";
import { unregister } from "./registerServiceWorker";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#379E54",
    },
    secondary: {
      main: "#FBB74D",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1200,
    },
  },
});

const history = createBrowserHistory();

// Routing middleware for react-router.
const reduxRouterMiddleware = routerMiddleware(history);

const middlewareList = [reduxRouterMiddleware, thunk];

if (process.env.NODE_ENV === "development") {
  const reduxLogger = createLogger({
    duration: true,
  });

  middlewareList.push(reduxLogger);
}

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewareList))
  );

  return store;
}

// Global application state. All react components will read from this through
// react context.
const store = configureStore();

// Render our application into <div id="root"></div> from index.html
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <MuiThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <App />
        </StylesProvider>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// Temporarily disable service workers.
unregister();
