import React, { useContext, useMemo } from "react";

import { ShopContext } from "context/ShopContext";

import StripeForm from "./Stripe/StripeForm";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

const fieldTypes = {
  email: {
    autoComplete: "email",
    icon: <EmailIcon />,
    placeholder: "Email",
    label: "Email",
    type: "email",
    name: "email",
    id: "email",
  },
  phone: {
    autoComplete: "tel_national",
    icon: <PhoneIcon />,
    placeholder: "Phone Number",
    label: "Phone Number",
    type: "tel",
    name: "phone",
    id: "phone",
  },
};

const PaymentForm = (props) => {
  const {
    selectedPaymentType,
    paymentInfo,
    setPaymentInfo,
    paymentFormRef,
    errors: _errors,
  } = useContext(ShopContext);

  const errors = useMemo(() => {
    return _errors.signupInfo || _errors.checkout || {};
  }, [_errors]);

  const handleChange = ({ target: { name, value } }) => {
    setPaymentInfo({ ...paymentInfo, [name]: value });
  };

  if (!selectedPaymentType) return null;

  const { fields } = selectedPaymentType;

  if (fields === "stripe")
    return <StripeForm {...props} ref={paymentFormRef} />;

  if (Array.isArray(fields)) {
    return fields.map((field) => (
      <Field key={field}>
        <TextInput
          {...fieldTypes[field]}
          onChange={handleChange}
          value={paymentInfo[field] || ""}
          // disabled={disabled}
          error={errors && errors[`payment.info.${field}`]}
          errorMessage={errors && errors[`payment.info.${field}`]}
        />
      </Field>
    ));
  }

  return null;
};

export default PaymentForm;
