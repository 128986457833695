import React, { useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import PromoView from "views/AdminView/Customization/PromoView";
import LogoCustomizeView from "views/AdminView/Customization/LogoCustomizeView";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CustomizeView = (props) => {
  const [expanded, setExpanded] = useState({
    logo: false,
    promo: false,
  });

  const toggleExpanded = (accordion) => () =>
    setExpanded((orig) => ({ ...orig, [accordion]: !orig[accordion] }));

  return (
    <div style={{ width: "100%" }}>
      <Accordion
        variant="outlined"
        expanded={expanded.logo}
        onChange={toggleExpanded("logo")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Logo
        </AccordionSummary>
        <AccordionDetails>
          <LogoCustomizeView />
        </AccordionDetails>
      </Accordion>

      <Accordion
        variant="outlined"
        expanded={expanded.promo}
        onChange={toggleExpanded("promo")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Informational Sidebar (Login Page)
        </AccordionSummary>
        <AccordionDetails>
          <PromoView />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomizeView;
