import React from "react";
import { useHistory } from "react-router";

import Button from "@material-ui/core/Button";

export default function ButtonLink(props) {
  const {
    children,
    color,
    disabled,
    icon,
    to,
    type,
    handleClick,
    variant,
  } = props;
  const { push } = useHistory();

  return (
    <Button
      variant={variant || "contained"}
      color={color || "primary"}
      startIcon={icon}
      disabled={disabled}
      type={type}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
        push(`${to}`);
      }}
    >
      {children}
    </Button>
  );
}
