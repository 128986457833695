import React, { useContext, forwardRef } from "react";
import { AccountContext } from "context/AccountContext";
import AddPaymentMethodForm from "components/ShopView/Stripe/AddPaymentMethodForm";
import CardOverview from "./CardOverview";
import { ShopContext } from "context/ShopContext";

import Field from "components/Layout/Field";
import CouponInput from "./CouponInput";

const StripeForm = forwardRef((props, ref) => {
  const { profile, subscription } = useContext(AccountContext);
  const { isUpgrade, isReceipt } = useContext(ShopContext);

  let components = [];
  if (!profile || !subscription || subscription.status.match(/failed/)) {
    components.push(
      <AddPaymentMethodForm {...props} ref={ref} key={components.length} />
    );
  } else if (isUpgrade) {
    components.push(<CardOverview {...props} key={components.length} />);
  }

  if (!isReceipt) {
    components.push(
      <Field key={components.length}>
        <CouponInput {...props} name='coupon' />
      </Field>
    );
  }

  return components;
});

export default StripeForm;
