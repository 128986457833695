import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { compose } from "recompose";

import FullArea from "components/Layout/FullArea";
import FallbackSwitch from "components/Routing/FallbackSwitch";
import withTheme from "composers/withTheme";

import HomeView from "./HomeView/HomeView";
import ManageAccountView from "./ManageAccountView/ManageAccountView";

import Echo from "laravel-echo";
import { AccountContext } from "context/AccountContext";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import LoadingContainer from "components/Loading/LoadingContainer";

import SubscriptionStatusNotification from "components/AccountView/SubscriptionStatusNotification";

function initWebsocket(token, profile, updateSubscription, updateProfile) {
  if (!!profile) {
    if (typeof window.io === "undefined") {
      window.io = require("socket.io-client");
    }

    if (typeof window.io !== "undefined") {
      if (typeof window.Echo === "undefined") {
        window.Echo = new Echo({
          broadcaster: "socket.io",
          host: window.location.hostname,
          authEndpoint: "/api/broadcasting/auth",
          auth: {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        });
      } else {
        window.Echo.connect();
      }
      if (typeof window.Echo !== "undefined") {
        window.Echo.private("user." + profile.parent_id)
          .listen("ProductOrderUpdated", ({ order }) => {
            if (order) {
              updateSubscription && updateSubscription(order);
            }
          })
          .listen("BalanceUpdated", ({ balance }) => {
            updateProfile && profile && updateProfile({ ...profile, balance });
          });
      }
    }
  }
}

const AccountViewBody = (props) => {
  const { profile, updateSubscription, updateProfile } =
    useContext(AccountContext);
  const [socketInit, updateSocketInit] = useState(false);

  useEffect(() => {
    if (!socketInit && profile && updateSubscription) {
      initWebsocket(
        sessionStorage.getItem("token"),
        profile,
        updateSubscription,
        updateProfile
      );
      updateSocketInit(true);
    }
  }, [profile, updateSubscription, updateProfile, socketInit]);

  if (!profile) return <LoadingContainer />;
  return (
    <FullArea>
      <SubscriptionStatusNotification />
      <FallbackSwitch fallback='/home'>
        <Route path='/account' component={ManageAccountView} />
        <Route exact path='/home' component={HomeView} />
      </FallbackSwitch>
    </FullArea>
  );
};

export default connect(null, (dispatch) => ({
  push: (url) => dispatch(push(url)),
}))(compose(withTheme)(AccountViewBody));
