import React, { useState, useEffect, useContext, useMemo } from "react";
import update from "immutability-helper";
import _ from "lodash";

import { H2, H3 } from "components/LoginView/Headings";
import Plan from "components/ShopView/Plan";
import SubscriptionDurationSelector from "components/ShopView/SubscriptionDurationSelector";
import LoadingContainer from "components/Loading/LoadingContainer";
import { AccountContext } from "context/AccountContext";
import { ShopContext } from "context/ShopContext";
import Dropdown from "components/Input/Dropdown";
import Field from "components/Layout/Field";

const ExploreBundlesView = (props) => {
  const { subscription } = useContext(AccountContext);

  const [{ duration }, setState] = useState({
    duration: 0,
  });

  const {
    options: { bundles, paymentTypesByCurrency },
    selectedCurrency,
    setSelectedCurrency,
    setSelectedBundle,
    isUpgrade,
  } = useContext(ShopContext);

  const subscriptionDuration = subscription?.duration;

  const currencyOptions = useMemo(() => {
    if (paymentTypesByCurrency) {
      const currencies = _.sortBy(
        Object.keys(paymentTypesByCurrency),
        (c) => paymentTypesByCurrency[c].sorting
      );

      let currencyOptions = [];
      for (const currency of currencies) {
        currencyOptions.push({
          label: currency.toUpperCase(),
          value: currency,
        });
      }

      return currencyOptions;
    }
    return [];
  }, [paymentTypesByCurrency]);

  useEffect(() => {
    if (bundles) {
      const duration =
        (isUpgrade && subscriptionDuration) || Object.keys(bundles)[0] || 0;
      setState((orig) =>
        update(orig, {
          duration: { $set: duration },
        })
      );
    }
  }, [bundles, isUpgrade, subscriptionDuration]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const handleCurrencyChange = ({ target }) => {
    const { value } = target;
    setSelectedCurrency(value);
  };

  const loaded = bundles && bundles[duration];

  return (
    <React.Fragment>
      <H2>{isUpgrade ? "Plans" : "Welcome!"}</H2>
      <H3>
        {isUpgrade
          ? "Select the plan that best suits your needs"
          : "Select a plan to start using Tusitawi"}
      </H3>
      {loaded ? (
        <React.Fragment>
          <Field>
            <SubscriptionDurationSelector
              onChange={handleChange}
              options={Object.keys(bundles)}
              bundles={bundles}
              name='duration'
              value={duration}
            />
          </Field>
          {currencyOptions && currencyOptions.length > 1 && (
            <Dropdown
              label='Currency'
              id='currency'
              name='currency'
              value={selectedCurrency}
              onChange={handleCurrencyChange}
              options={currencyOptions}
            />
          )}
          {bundles[duration].map((bundle, i) => {
            return (
              <Plan
                key={i}
                bundle={bundle}
                onClick={() => setSelectedBundle(bundle)}
              />
            );
          })}
        </React.Fragment>
      ) : (
        <LoadingContainer />
      )}
    </React.Fragment>
  );
};

export default ExploreBundlesView;
