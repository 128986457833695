import styled from "styled-components";

const FullArea = styled.div`
  position: relative;
  width: 100%;
  min-height: 100%;

  ${({ centered }) =>
    centered &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export default FullArea;
