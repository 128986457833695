import React, { useEffect, useState } from "react";
// import { compose } from "recompose";
// import withTheme from "composers/withTheme";
import _ from "lodash";

import {
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // TextareaAutosize,
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import CancelIcon from "@material-ui/icons/Block";

import LoadingContainer from "components/Loading/LoadingContainer";
import RegionService from "services/RegionService";
import Field from "components/Layout/Field";
import Button from "components/Input/Button";
import TextInput from "components/Input/TextInput";

const AddRegionDialog = (props) => {
  const { open, onSave, onClose } = props;

  const [{ name, code }, setState] = useState({
    name: "",
    code: "",
  });
  const [waiting, setWaiting] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const handleClose = () => {
    setState({ name: "", code: "" });
    onClose && onClose();
  };

  const handleSaveAndExit = async () => {
    await handleSaveAndContinue();
    onClose && onClose();
  };

  const handleSaveAndContinue = async () => {
    setWaiting(true);
    const regionData = await RegionService.addRegion(name, code);
    onSave && onSave(regionData);
    setState({ name: "", code: "" });
    setWaiting(false);
  };

  return (
    <Dialog open={open}>
      {waiting && <LoadingContainer />}
      <DialogTitle>Add New Region</DialogTitle>
      <DialogContent>
        <Field>
          <TextInput
            inputProps={{ autoFocus: true }}
            label="Name"
            value={name}
            name="name"
            onChange={handleChange}
          />
        </Field>
        <Field>
          <TextInput
            label="Short Code"
            value={code}
            name="code"
            onChange={handleChange}
          />
        </Field>
      </DialogContent>
      <DialogActions>
        <Button disabled={waiting} color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={waiting}
          color="primary"
          onClick={handleSaveAndContinue}
        >
          Save &amp; Add Another
        </Button>
        <Button disabled={waiting} color="primary" onClick={handleSaveAndExit}>
          Save &amp; Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/*
const BulkAddRegionsDialog = compose(withTheme)((props) => {
  const { open, onSave, onClose, responsiveSize } = props;

  const [data, setData] = useState("");
  const [waiting, setWaiting] = useState(false);

  const handleChange = ({ target: { value } }) => {
    setData(value);
  };
  const handleSave = () => {
    setWaiting(true);
    onSave && onSave();
    setWaiting(false);
  };

  const width = responsiveSize === "phone" ? "100%" : "400px";
  return (
    <Dialog open={open}>
      {waiting && <LoadingContainer />}
      <DialogTitle>Bulk Add Regions</DialogTitle>
      <DialogContent>
        <TextInput
          style={{ width }}
          inputProps={{
            autoFocus: true,
            inputComponent: TextareaAutosize,
            inputProps: {
              rowsMin: 4,
            },
          }}
          value={data}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={waiting} color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={waiting} color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
*/

const RegionEntry = (props) => {
  const {
    data: { region_id, name, code },
    onSave,
    onDelete,
  } = props;

  const [state, setState] = useState({
    name,
    code,
    editMode: false,
    waiting: false,
  });

  const enableEditMode = () =>
    setState((orig) => ({ ...orig, editMode: true }));
  const cancelEditMode = () => setState({ name, code, editMode: false });

  const handleChange = ({ target: { name, value } }) => {
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const handleSave = async () => {
    setState((orig) => ({ ...orig, waiting: true }));
    const savedData = await RegionService.saveRegion(
      region_id,
      state.name,
      state.code
    );
    onSave && onSave(savedData);
    setState({
      name: savedData.name || "",
      code: savedData.code || "",
      editMode: false,
      waiting: false,
    });
  };

  const handleDelete = async () => {
    if (
      window.confirm(`Are you sure you want to delete the region: ${name}?`)
    ) {
      setState((orig) => ({ ...orig, waiting: true }));
      await RegionService.deleteRegion(region_id);
      onDelete && onDelete();
    }
  };

  if (state.editMode) {
    return (
      <TableRow style={{ position: "relative" }}>
        <TableCell>
          <TextInput name="name" onChange={handleChange} value={state.name} />
        </TableCell>
        <TableCell>
          <TextInput name="code" onChange={handleChange} value={state.code} />
        </TableCell>
        <TableCell>
          {state.waiting && <LoadingContainer />}
          <ButtonGroup>
            <Button disabled={state.waiting} onClick={handleSave}>
              <SaveIcon style={{ color: "green" }} />
            </Button>
            <Button disabled={state.waiting} onClick={cancelEditMode}>
              <CancelIcon style={{ color: "red" }} />
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow style={{ position: "relative" }}>
        <TableCell>{state.name}</TableCell>
        <TableCell>{state.code}</TableCell>
        <TableCell style={{ textAlign: "right" }}>
          {state.waiting && <LoadingContainer />}
          <ButtonGroup>
            <Button disabled={state.waiting} onClick={enableEditMode}>
              <EditIcon />
            </Button>
            <Button disabled={state.waiting} onClick={handleDelete}>
              <DeleteIcon style={{ color: "red" }} />
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    );
  }
};

const RegionListView = (props) => {
  const [regions, setRegions] = useState(null);
  const [showingDialog, setDialog] = useState(-1);
  const [waiting, setWaiting] = useState(false);

  const loadRegions = async () => {
    const regions = await RegionService.getRegions();
    setRegions(regions);
  };

  const showAddDialog = () => {
    setDialog(1);
  };
  // const showBulkAddDialog = () => {
  //   setDialog(2);
  // };
  const closeDialog = () => {
    setDialog(-1);
  };

  const regionAdded = () => {
    setRegions(null);
    loadRegions();
  };

  const handleUpdate =
    (region_id) =>
    (e, { name, code }) => {
      setRegions((orig) =>
        _.map(orig, (region) =>
          region.region_id === region_id ? { ...region, name, code } : region
        )
      );
    };

  const handleDelete = (region_id) => () => {
    setRegions((orig) =>
      _.filter(orig, (region) => region.region_id !== region_id)
    );
  };

  const handleDeleteAll = async () => {
    if (
      window.confirm(
        "Are you sure want to remove ALL regions? (This action is not reversible)"
      )
    ) {
      setWaiting(true);
      await RegionService.deleteAll();
      setRegions([]);
      setWaiting(false);
    }
  };

  const mount = () => {
    loadRegions();
  };
  useEffect(mount, []);

  return (
    <div style={{ width: "100%" }}>
      {(!regions || waiting) && <LoadingContainer />}
      <ButtonGroup>
        <Button variant='contained' color='primary' onClick={showAddDialog}>
          Add
        </Button>
        {/* <Button variant="contained" color="primary" onClick={showBulkAddDialog}>
          Bulk Add
        </Button> */}
        <Button variant='contained' color='secondary' onClick={handleDeleteAll}>
          Delete All
        </Button>
      </ButtonGroup>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell colSpan={2} style={{ fontWeight: "bold" }}>
                Short Code
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!regions || regions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} color='grey'>
                  Region list empty
                </TableCell>
              </TableRow>
            ) : (
              regions.map((region) => (
                <RegionEntry
                  key={region.region_id}
                  data={region}
                  onDelete={handleDelete(region.region_id)}
                  onUpdate={handleUpdate(region.region_id)}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AddRegionDialog
        open={showingDialog === 1}
        onClose={closeDialog}
        onSave={regionAdded}
      />
      {/* <BulkAddRegionsDialog
        open={showingDialog === 2}
        onClose={closeDialog}
        onSave={regionAdded}
      /> */}
    </div>
  );
};

export default RegionListView;
