import React, { useState } from "react";
import { compose } from "recompose";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import withTheme from "composers/withTheme";

import uniqueId from "lodash/uniqueId";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const TextInput = compose(withTheme)((props) => {
  const [uuid] = useState(uniqueId());
  const [state, setState] = useState({
    isPassword: props.type === "password",
    passwordIsVisible: false,
  });

  const handleChange = (e) => {
    props.onChange && props.onChange(e);
  };

  const togglePasswordVisibility = () => {
    setState((orig) => ({
      ...orig,
      passwordIsVisible: !orig.passwordVisibility,
    }));
  };

  const {
    autoComplete,
    disabled,
    error,
    errorMessage,
    icon,
    label,
    max,
    min,
    name,
    placeholder,
    responsiveSize,
    type,
    value,
    style = {},
    inputProps = {},
    inputRef,
  } = props;

  return (
    <FormControl
      variant="outlined"
      disabled={disabled}
      error={!!error}
      style={{ ...style, alignSelf: "center" }}
      margin={responsiveSize === "phone" ? "dense" : "none"}
      fullWidth
    >
      {label && <InputLabel htmlFor={uuid}>{label}</InputLabel>}
      <OutlinedInput
        inputRef={inputRef}
        fullWidth
        type={
          state.isPassword && state.passwordIsVisible
            ? "text"
            : state.isPassword
            ? "password"
            : type
        }
        placeholder={placeholder}
        startAdornment={
          icon && <InputAdornment position="start">{icon}</InputAdornment>
        }
        endAdornment={
          state.isPassword && (
            <InputAdornment position="end">
              <IconButton
                onClick={togglePasswordVisibility}
                aria-label="Toggle password visibillity"
              >
                {state.passwordIsVisible ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </InputAdornment>
          )
        }
        inputProps={{ min, max, autoComplete }}
        id={uuid}
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        {...inputProps}
      />
      {error && errorMessage && (
        <FormHelperText id={`${uuid}-error-text`}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
});

export default React.forwardRef((props, ref) => (
  <TextInput {...props} inputRef={ref} />
));
