import styled from "styled-components";

export const H2 = styled.h2`
  margin-top: 0;
  font-size: 32px;
  line-height: 41px;
  font-weight: normal;
  font-style: normal;
`;
export const H3 = styled.h3`
  margin-top: 0;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
`;
