import React, { useContext, useState, useRef } from "react";
import { AccountContext } from "context/AccountContext";
import AddPaymentMethodForm from "components/ShopView/Stripe/AddPaymentMethodForm";
import { connect } from "react-redux";
import { goBack, push } from "connected-react-router";
import { H2 } from "components/AccountView/Headings";
import Button from "components/Input/Button";
import FlexButtonGroup from "components/Layout/FlexButtonGroup";
import Form from "components/LoginView/Input/Form";
import ShopService from "services/ShopService";
import LoadingContainer from "components/Loading/LoadingContainer";

const UpdatePaymentView = (props) => {
  const { subscription } = useContext(AccountContext);

  const [disabled, setDisabled] = useState(false);

  const ref = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (disabled) return;

    setDisabled(true);

    if (ref?.current) {
      const paymentInfo =
        ref.current?.beforeSubmit && (await ref.current.beforeSubmit());

      if (paymentInfo?.error || paymentInfo === false) {
        setDisabled(false);
        return;
      }

      await ShopService.updatePaymentMethod(paymentInfo);
      props.push("/account");
      return;
    }

    setDisabled(false);
  };
  const handleCancel = () => {
    if (disabled) return;

    props.goBack();
  };

  return (
    <React.Fragment>
      <H2>Update Payment Method</H2>
      <Form onSubmit={handleSubmit}>
        {disabled && <LoadingContainer />}
        {(() => {
          switch (subscription?.payment_method) {
            case "stripe":
              return (
                <AddPaymentMethodForm ref={ref} disableLoadingSpinner={true} />
              );
            default:
              props.goBack();
              return null;
          }
        })()}
        <FlexButtonGroup justifyContent="flex-end">
          <Button disabled={disabled} variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={disabled} type="submit">
            Update
          </Button>
        </FlexButtonGroup>
      </Form>
    </React.Fragment>
  );
};

export default connect(null, (dispatch) => ({
  goBack: () => dispatch(goBack()),
  push: (url) => dispatch(push(url)),
}))(UpdatePaymentView);
