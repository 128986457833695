import { isInteger } from "lodash";
import styled from "styled-components";

export default styled.div`
  ${({ gap = 12, justifyContent = "flex-start" }) => {
    if (isInteger(gap)) gap = `${gap}px`;

    return `
      width: calc(100% + ${gap});
      display: flex;
      justify-content: ${justifyContent};
      align-items: center;
      margin: -${gap} 0 0 -${gap};

      & > * {
        margin: ${gap} 0 0 ${gap};
        width: unset;
      }
    `;
  }}
`;
