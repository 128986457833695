import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import Button from "components/Input/Button";

class LogoutModal extends React.Component {
  render() {
    const { open, onCancel, onLogoutConfirmed } = this.props;

    return (
      <Dialog
        aria-describedby="logout-dialog-confirmation"
        open={open}
        disableBackdropClick
        style={{ zIndex: "1500" }}
      >
        <DialogTitle id="logout-dialog-confirmation" align="center">
          Are you sure you want to log out?
        </DialogTitle>
        <Divider />
        <DialogActions>
          <Grid container justify="space-around" alignItems="center">
            <Button onClick={onCancel} size="large" variant="outlined">
              Cancel
            </Button>
            <Button onClick={onLogoutConfirmed} size="large">
              Log Out
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

export default LogoutModal;
