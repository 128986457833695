import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import styled from "styled-components";

import ExploreBundlesView from "./ExploreBundlesView";
import CheckoutView from "./CheckoutView";

import ScrollableArea from "components/Layout/ScrollableArea";
import ShopStepper from "components/ShopView/ShopStepper";

import { AccountContext } from "context/AccountContext";
import SignUpView from "views/LandingView/SignUpView";
import { H2 } from "components/LoginView/Headings";
import Button from "components/Input/Button";
import Plan from "components/ShopView/Plan";
import _Form from "components/LoginView/Input/Form";
import Field from "components/Layout/Field";
import { Checkbox, FormControlLabel, Link as MuiLink } from "@material-ui/core";
import FlexButtonGroup from "components/Layout/FlexButtonGroup";
import TermsAndConditionsDialog from "components/ShopView/TermsAndConditionsDialog";
import { ShopContext, ShopProvider } from "context/ShopContext";

const Form = styled(_Form)`
  font-family: sans-serif;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const H2Centered = styled(H2)`
  padding: 0;
  margin: 0;
`;

const ShopViewInner = (props) => {
  const { isLoggedIn, subscription } = useContext(AccountContext);

  const history = useHistory();

  const {
    selectedBundle,
    isUpgrade,
    isRenewal,
    canCheckout,
    handleSubmit,
    toggleConfirmPayment,
    toggleConfirmTerms,
    confirmations,
    prevent_submit,
  } = useContext(ShopContext);

  const [step, setStep] = useState(1);

  const [editBundle, setEditBundle] = useState(false);

  const [state, setState] = useState({
    showTerms: false,
  });

  const incrementStep = () => setStep((orig) => ++orig);

  const showTermsAndConditions = (e) => {
    e.preventDefault();
    setState((orig) => ({ ...orig, showTerms: true }));
  };

  const hideTermsAndConditions = (e) => {
    e.preventDefault();
    setState((orig) => ({ ...orig, showTerms: false }));
  };

  const handleCancel = () => {
    if (step === 0 || window.confirm("Are you sure you want to cancel?")) {
      history.goBack();
    }
  };

  useEffect(() => {
    if (selectedBundle !== null) {
      setStep((orig) => (orig === 1 ? 2 : orig));
      setState((orig) => ({ ...orig, confirmPayment: false }));
      setEditBundle(false);
    }
  }, [selectedBundle]);

  const finalStep = isLoggedIn() ? 2 : 3;
  const isFinalStep = () => step >= finalStep;

  const ConfirmationChecks = (
    <React.Fragment>
      <hr />
      <FormControlLabel
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
        control={
          <Checkbox
            id='confirmPayment'
            color='primary'
            onClick={toggleConfirmPayment}
            checked={confirmations.payment}
          />
        }
        label={`I agree to ${
          isUpgrade && !isRenewal ? "change plans and " : ""
        }pay the Total as shown above`}
      />
      <FormControlLabel
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
        control={
          <Checkbox
            id='confirmTerms'
            color='primary'
            onClick={toggleConfirmTerms}
            checked={confirmations.terms}
          />
        }
        label={
          <span>
            I accept Tusitawi{" "}
            <MuiLink onClick={showTermsAndConditions}>
              {"Terms & Conditions"}
            </MuiLink>
          </span>
        }
      />
    </React.Fragment>
  );

  return (
    <ScrollableArea
      centered
      style={{
        fontFamily: "sans-serif",
        maxWidth: "450px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <TermsAndConditionsDialog
        open={state.showTerms}
        onClose={hideTermsAndConditions}
        onAccept={() =>
          setState((orig) => ({
            ...orig,
            confirmTerms: true,
            showTerms: false,
          }))
        }
        clickAway={false}
      />
      {isLoggedIn() && <H2>{isUpgrade ? "Change Plan" : "Sign Up"}</H2>}
      <Form onSubmit={handleSubmit}>
        <FlexButtonGroup>
          {isLoggedIn() && (
            <Button variant='outlined' onClick={handleCancel}>
              Cancel
            </Button>
          )}
          {step <= 0 && (
            <Button
              style={{
                flexGrow: "1",
                marginBottom: isLoggedIn() ? null : "6px",
              }}
              onClick={() => setStep(1)}
              color='secondary'
            >
              {isLoggedIn() ? "Begin" : "Begin Registration"}
            </Button>
          )}
        </FlexButtonGroup>
        {!isLoggedIn() && (
          <Link to='/' style={{ color: "white", textAlign: "center" }}>
            Already registered? Click here to log in
          </Link>
        )}
        <ShopStepper
          step='1'
          currentStep={step}
          pre={<H2Centered>Select Plan</H2Centered>}
          post={
            editBundle ? (
              <React.Fragment>
                <ExploreBundlesView />
                <FlexButtonGroup justifyContent='flex-end'>
                  <Button
                    color='secondary'
                    onClick={() => setEditBundle(false)}
                  >
                    Cancel
                  </Button>
                </FlexButtonGroup>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {isUpgrade && !isRenewal && (
                  <React.Fragment>
                    <H2>Your current Plan</H2>
                    <Plan
                      bundle={subscription}
                      hideButton={true}
                      hideBanner={true}
                      disabled={true}
                    />
                  </React.Fragment>
                )}
                <H2>{isRenewal ? "Renew your plan" : "Your new Plan"}</H2>
                <Plan
                  bundle={selectedBundle}
                  hideButton={true}
                  hideBanner={true}
                  disabled={true}
                  variant={"inverted"}
                />
                <FlexButtonGroup justifyContent='flex-end'>
                  <Button color='secondary' onClick={() => setEditBundle(true)}>
                    Change Plan
                  </Button>
                </FlexButtonGroup>
              </React.Fragment>
            )
          }
          loading={prevent_submit}
        >
          <ExploreBundlesView />
        </ShopStepper>
        <ShopStepper
          step='2'
          currentStep={step}
          pre={<H2Centered>Payment Info</H2Centered>}
          loading={prevent_submit}
        >
          <CheckoutView
            name='payment'
            checkoutText={props.checkoutText}
            disabled={prevent_submit}
            disableLoadingSpinner={true}
          />
          {step === 2 && (
            <React.Fragment>
              {isFinalStep() && ConfirmationChecks}
              <Field>
                {isFinalStep() ? (
                  <Button disabled={!canCheckout} type='submit'>
                    Checkout
                  </Button>
                ) : (
                  <Button disabled={prevent_submit} onClick={incrementStep}>
                    Proceed
                  </Button>
                )}
              </Field>
            </React.Fragment>
          )}
        </ShopStepper>
        {finalStep > 2 && (
          <ShopStepper
            step='3'
            currentStep={step}
            pre={<H2Centered>Account Info</H2Centered>}
            loading={prevent_submit}
          >
            <SignUpView disabled={prevent_submit} />
            {ConfirmationChecks}
            <Field style={{ marginTop: "20px" }}>
              <Button disabled={!canCheckout || prevent_submit} type='submit'>
                Checkout
              </Button>
            </Field>
          </ShopStepper>
        )}
      </Form>
    </ScrollableArea>
  );
};

const ShopView = (props) => {
  return (
    <ShopProvider>
      <ShopViewInner {...props} />
    </ShopProvider>
  );
};

export default ShopView;
