import React, { useContext } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { compose } from "recompose";
import styled from "styled-components";

import _Panel from "components/AccountView/Input/Panel";
import ScrollableArea from "components/Layout/ScrollableArea";
import FallbackSwitch from "components/Routing/FallbackSwitch";
import withTheme from "composers/withTheme";
import { push } from "connected-react-router";

import ManageProfileView from "./ManageProfileView";
import ManageStudentsView from "./ManageStudentsView";
import NewOrEditStudentView from "./NewOrEditStudentView";

import { AccountContext } from "context/AccountContext";
import ReceiptView from "./ReceiptView";
import CancelSubscriptionView from "./CancelSubscriptionView";
import UpdatePaymentView from "./UpdatePaymentView";
import RenewSubscriptionView from "./RenewSubscriptionView";
import ShopView from "views/ShopView/ShopView";

const Panel = compose(withTheme)(styled(_Panel)`
  ${({ responsiveSize }) =>
    responsiveSize !== "phone" &&
    `
    padding: 45px;
  `}
`);

const ManageAccount = (props) => {
  const { path } = props.match;
  const { responsiveSize } = props;

  const { subscription } = useContext(AccountContext);

  return (
    <ScrollableArea centered style={{ fontFamily: "sans-serif" }}>
      <Panel invisible={responsiveSize === "phone" ? 1 : 0}>
        <FallbackSwitch fallback={`${path}/`}>
          {false !== subscription && (
            <Route
              exact
              key='new_student'
              path={`${path}/students/new`}
              render={(routerProps) => (
                <NewOrEditStudentView
                  onSuccess={() => props.history.goBack()}
                  {...routerProps}
                />
              )}
            />
          )}
          {false !== subscription && (
            <Route
              exact
              key='edit_student'
              path={`${path}/students/edit/:student_id`}
              render={(routerProps) => (
                <NewOrEditStudentView
                  onSuccess={() => props.history.goBack()}
                  {...routerProps}
                />
              )}
            />
          )}
          {false !== subscription && (
            <Route
              exact
              path={`${path}/students`}
              component={ManageStudentsView}
            />
          )}
          <Route path={`${path}/bundles`} component={ShopView} />
          <Route
            path={[`${path}/receipt/:order_id`, `${path}/receipt`]}
            component={ReceiptView}
          />
          <Route path={`${path}/cancel`} component={CancelSubscriptionView} />
          <Route path={`${path}/renew`} component={RenewSubscriptionView} />
          <Route
            path={`${path}/update_payment`}
            component={UpdatePaymentView}
          />
          <Route
            exact
            path={`${path}/`}
            render={(routerProps) => (
              <ManageProfileView
                onSuccess={() => props.push(path)}
                {...routerProps}
              />
            )}
          />
        </FallbackSwitch>
      </Panel>
    </ScrollableArea>
  );
};

export default compose(
  withTheme,
  connect(null, (dispatch) => ({
    push: (url) => dispatch(push(url)),
  }))
)(ManageAccount);
