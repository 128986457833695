import React, { useContext, useEffect } from "react";
import { H2, H3 } from "components/LoginView/Headings";
import { AccountContext } from "context/AccountContext";
import OrderOverview from "../../../components/ShopView/OrderOverview";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LoadingContainer from "components/Loading/LoadingContainer";
import FixPaymentView from "./FixPaymentView";
import { ShopContext, ShopProvider } from "context/ShopContext";

const ReceiptViewInner = (props) => {
  const {
    currentOrder: receipt,
    receiptMessage,
    errors: { receiptView: error },
  } = useContext(ShopContext);

  // const refreshTimer = useRef();
  // const clearRefreshTimer = () => {
  //   if (refreshTimer.current) {
  //     clearTimeout(refreshTimer.current);
  //     refreshTimer.current = null;
  //   }
  // };
  // const startRefreshTimer = () => {
  //   clearRefreshTimer();

  //   if (params.order_id) {
  //     refreshTimer.current = setTimeout(() => {
  //       loadOrder(params.order_id);
  //     }, REFRESH_TIMEOUT);
  //   }
  // };

  // useEffect(() => {
  //   if (receipt?.status === "pending_payment") {
  //     startRefreshTimer();
  //   }
  // }, [receipt]);

  if (!receipt) return <LoadingContainer />;

  return (
    <React.Fragment>
      {(() => {
        if (error) {
          return (
            <React.Fragment>
              <H2 style={{ fontWeight: "bold", color: "#35B158" }}>Oops!</H2>
              <H3>{error}</H3>
            </React.Fragment>
          );
        } else {
          switch (receipt.status) {
            case "pending_payment":
              return (
                <React.Fragment>
                  <H2 style={{ fontWeight: "bold", color: "#35B158" }}>
                    Pending Payment
                  </H2>
                  <H3>{receiptMessage}</H3>
                </React.Fragment>
              );
            case "active":
              return (
                <React.Fragment>
                  <H2 style={{ fontWeight: "bold", color: "#35B158" }}>
                    <CheckCircleIcon /> Payment Complete
                  </H2>
                  <H3>{receiptMessage}</H3>
                </React.Fragment>
              );
            case "failed":
            case "failed_declined":
              return (
                <React.Fragment>
                  <H2 style={{ fontWeight: "bold", color: "red" }}>
                    <CancelIcon /> Payment Failed
                  </H2>
                  <H3>{receiptMessage}</H3>
                  <FixPaymentView />
                </React.Fragment>
              );
            default:
          }
        }
      })()}
      <H2 style={{ textAlign: "left", borderBottom: "1px solid black" }}>
        {receipt.status === "active" ? "Order Receipt" : "Your Order"}
      </H2>
      <OrderOverview bundle={receipt} highlightPlan={false} />
      <H3>Thank you for learning with Tusitawi!</H3>
    </React.Fragment>
  );
};

const ReceiptView = (props) => {
  const { subscription } = useContext(AccountContext);

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const order_id = params.order_id;
    if (!order_id && subscription) {
      history.replace(`${location.pathname}/${subscription.product_order_id}`);
    }
  }, [params.order_id, history, location.pathname, subscription]);

  return (
    <ShopProvider receiptId={params.order_id}>
      <ReceiptViewInner {...props} />
    </ShopProvider>
  );
};

export default ReceiptView;
