import React from "react";

import IconButton from "@material-ui/core/IconButton";

import BackArrowIcon from "@material-ui/icons/ArrowBack";

export default (props) => (
  <IconButton aria-label="Go Back" color="primary" {...props}>
    <BackArrowIcon />
  </IconButton>
);
