import React, { useContext } from "react";

import Button from "components/Input/Button";
import FlexButtonGroup from "components/Layout/FlexButtonGroup";
import Form from "components/LoginView/Input/Form";
import LoadingContainer from "components/Loading/LoadingContainer";

import PaymentForm from "components/ShopView/PaymentForm";
import { ShopContext } from "context/ShopContext";

const FixPaymentView = (props) => {
  const { handleFixPayment, prevent_submit } = useContext(ShopContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFixPayment();
  };

  return (
    <Form onSubmit={handleSubmit}>
      {prevent_submit && <LoadingContainer />}
      <PaymentForm name='paymentInfo' disableLoadingSpinner />
      <FlexButtonGroup justifyContent='flex-end'>
        <Button disabled={prevent_submit} type='submit'>
          {"Update & Retry"}
        </Button>
      </FlexButtonGroup>
    </Form>
  );
};

export default FixPaymentView;
