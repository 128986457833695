import React, { useContext, useState } from "react";
import { compose } from "recompose";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import LogoutModal from "components/AccountView/LogoutModal";
import AppBar from "@material-ui/core/AppBar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TusitawiLogo from "components/Logo/TusitawiLogo";
import withTheme from "composers/withTheme";

import ChevronRight from "@material-ui/icons/ChevronRight";
import BackArrowIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import { NavigationContext } from "context/NavigationContext";

const Navbar = styled(AppBar)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1400;
`;

const MobileNavigationMenu = styled(List)`
  margin-top: 58px;
  width: 250px;
  overflow-x: hidden;
`;

const MobileLinkWrapper = styled(ListItem)`
  background-color: ${({ current }) => current && "rgba(251, 183, 77, 0.5)"};
`;

const AnimatedListItemIcon = styled(ListItemIcon)`
  & svg {
    transition: transform 0.2s;
    ${({ expanded }) =>
      expanded &&
      `
    transform: rotate(90deg);
  `}
  }
`;

const MobileLink = (props) => {
  const { match, icon, label, menuExpanded, ...rest } = props;

  return (
    <MobileLinkWrapper button current={!!match ? 1 : 0} {...rest}>
      <AnimatedListItemIcon expanded={menuExpanded}>
        {icon}
      </AnimatedListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MobileLinkWrapper>
  );
};

const DesktopLink = styled(Tab)`
  width: 185px;
  font-weight: 700;
`;
const DesktopNavbar = (props) => {
  const history = useHistory();

  const [menuState, setMenuState] = useState({
    openMenu: -1,
    anchor: null,
  });

  const { headerHeight, onLogoutClick, currentLinkIndicies, children, links } =
    props;

  const toggleMenu = ({ currentTarget }, value = -1) => {
    setMenuState((orig) => ({
      openMenu: value === orig.openMenu ? -1 : value,
      anchor: value > -1 ? currentTarget : null,
    }));
  };

  const generateDesktopLinks = (links) => {
    return links.map(({ to, label, disabled, menuItems, type }, i) => {
      if (Array.isArray(menuItems)) {
        return [
          <DesktopLink
            key={`${i}a`}
            style={{ height: headerHeight }}
            label={label}
            value={null}
            onClick={(e) => toggleMenu(e, i)}
            tabIndex={0}
          />,
          <Menu
            key={`${i}b`}
            open={menuState.openMenu === i}
            anchorEl={menuState.anchor}
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
            onClose={toggleMenu}
            getContentAnchorEl={null}
          >
            {menuItems.map(({ to, label, disabled }, i) => (
              <MenuItem key={i} disabled={disabled} component="a" href={to}>
                {label}
              </MenuItem>
            ))}
          </Menu>,
        ];
      } else if (type === "logout") {
        return (
          <DesktopLink
            style={{ height: headerHeight }}
            key={i}
            value={null}
            label={label}
            onClick={onLogoutClick}
            tabIndex={0}
          />
        );
      } else {
        return (
          <DesktopLink
            style={{ height: headerHeight }}
            key={i}
            value={i}
            label={label}
            onClick={() => history.push(to)}
            tabIndex={0}
            disabled={disabled}
          />
        );
      }
    });
  };

  return (
    <Navbar position="static" style={{ height: headerHeight }}>
      <Toolbar variant="dense">
        <Grid container justify="flex-start" alignItems="flex-end" item md={2}>
          <Link to="/">
            <TusitawiLogo type="nav_logo" />
          </Link>
        </Grid>
        <Grid container justify="flex-end" item md={10}>
          <Toolbar>
            <Tabs
              TabIndicatorProps={{
                style: { backgroundColor: "#fff", height: "4px" },
              }}
              value={(currentLinkIndicies && currentLinkIndicies[0]) || 0}
            >
              {generateDesktopLinks(links)}
            </Tabs>
          </Toolbar>
        </Grid>
      </Toolbar>
      {children}
    </Navbar>
  );
};

const MobileNavbar = (props) => {
  const history = useHistory();

  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [collapsedPanels, setCollapsedPanels] = useState({});

  const { backArrowFunc } = useContext(NavigationContext);

  const { headerHeight, onLogoutClick, currentLink, children, links } = props;

  const toggleSidebar = (e, value) => {
    setSidebarExpanded((orig) =>
      typeof value === "undefined" ? !orig : value
    );
  };

  const toggleCollapsePanel = (panel) => {
    setCollapsedPanels((orig) => ({ ...orig, [panel]: !orig[panel] }));
  };

  const handleLinkClick = (to) => {
    history.push(to);
    setSidebarExpanded(false);
  };

  const generateMobileLinks = (links) => {
    return links.map(({ to, type, label, icon, menuItems, disabled }, i) => {
      return (
        <div key={i}>
          {(() => {
            if (Array.isArray(menuItems)) {
              return (
                <React.Fragment>
                  <MobileLink
                    current={currentLink?.to === to ? 1 : 0}
                    onClick={(e) => toggleCollapsePanel(i)}
                    menuExpanded={collapsedPanels[i] ? 1 : 0}
                    icon={<ChevronRight />}
                    label={label}
                    disabled={disabled}
                  />
                  <Collapse in={collapsedPanels[i]}>
                    {menuItems &&
                      menuItems.map(({ to, label, icon, disabled }, i) => (
                        <MobileLink
                          key={i}
                          component="a"
                          target="_blank"
                          style={{ paddingLeft: "35px" }}
                          current={currentLink && currentLink.to === to ? 1 : 0}
                          href={to}
                          icon={icon}
                          label={label}
                          disabled={disabled}
                        />
                      ))}
                  </Collapse>
                </React.Fragment>
              );
            } else if (type === "logout") {
              return (
                <MobileLink
                  current={currentLink?.to === to ? 1 : 0}
                  onClick={onLogoutClick}
                  icon={icon}
                  label={label}
                  disabled={disabled}
                />
              );
            } else {
              return (
                <MobileLink
                  current={currentLink?.to === to ? 1 : 0}
                  onClick={(e) => handleLinkClick(to)}
                  icon={icon}
                  label={label}
                  disabled={disabled}
                />
              );
            }
          })()}
          {i < links.length - 1 && <Divider />}
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <Navbar position="static" height={headerHeight}>
        <Toolbar>
          {backArrowFunc ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => backArrowFunc()}
            >
              <BackArrowIcon />
            </IconButton>
          ) : (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={(e) => toggleSidebar()}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Grid container justify="center">
            <Typography variant="h6" align="center">
              {currentLink?.label}
            </Typography>
          </Grid>
        </Toolbar>
      </Navbar>
      <Drawer open={isSidebarExpanded} onClose={(e) => toggleSidebar()}>
        <MobileNavigationMenu value={null}>
          {generateMobileLinks(links)}
        </MobileNavigationMenu>
      </Drawer>
      {children}
    </React.Fragment>
  );
};

const ResponsiveNavbar = (props) => {
  const history = useHistory();

  const {
    responsiveSize,
    location: { pathname },
    links,
    children,
  } = props;

  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);

  const toggleLogoutModal = (value) => {
    setLogoutModalOpen((orig) =>
      typeof value === "undefined" ? !orig : value
    );
  };

  const getCurrentLink = (list) => {
    let checkLinks = list;
    if (typeof checkLinks === "undefined") {
      checkLinks = links;
    }

    for (const i in checkLinks) {
      const { to, menuItems, exact } = checkLinks[i];

      let match = false;
      if (exact) {
        match = pathname === to;
      } else {
        match = pathname.startsWith(to);
      }

      if (match) {
        let childLink;
        if (menuItems && (childLink = getCurrentLink(menuItems))) {
          return {
            link: childLink.link,
            indicies: [parseInt(i), ...childLink.indicies],
          };
        }
        return { link: checkLinks[i], indicies: [parseInt(i)] };
      }
    }

    return null;
  };

  const currentLinkInfo = getCurrentLink() || {};
  const { link: currentLink = null, indicies: currentLinkIndicies = null } =
    currentLinkInfo;

  const NavbarToDisplay =
    responsiveSize === "desktop" ? DesktopNavbar : MobileNavbar;

  return (
    <NavbarToDisplay
      links={links}
      onLogoutClick={() => toggleLogoutModal(true)}
      currentLink={currentLink}
      currentLinkIndicies={currentLinkIndicies}
      {...props}
    >
      <LogoutModal
        open={isLogoutModalOpen}
        onCancel={() => toggleLogoutModal(false)}
        onLogoutConfirmed={() => history.push("/logout")}
      />
      {children}
    </NavbarToDisplay>
  );
};

export default compose(withTheme)(ResponsiveNavbar);
