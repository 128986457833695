import styled from "styled-components";
import { compose } from "recompose";
import withTheme from "composers/withTheme";

const FreeTrial = compose(withTheme)(styled.div`
  position: absolute;
  user-select: none;
  z-index: 10000;

  background-color: #104f66;
  color: #fafafa;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 190px;
  height: 54px;
  top: 0;
  transform: translateY(-50%);

  ${({ responsiveSize }) =>
    responsiveSize !== "phone"
      ? `
    right: -75px;
  `
      : `
    left: 50%;
    transform: translate(-50%, -50%);
  `}

  &::after {
    content: "Free Trial!";
  }
`);

export default FreeTrial;
