import { compose } from "recompose";
import styled from "styled-components";

import withTheme from "composers/withTheme";

const Panel = compose(withTheme)(styled.div`
  position: relative;

  ${({ invisible, responsiveSize }) =>
    invisible
      ? `
        width: 100%;
        margin: 0;
        padding: 0 15px;
        & > & {
          padding: 0;
        }
      `
      : `
        padding: 24px;
        background-color: #FAFAFA;
        border: 1px solid #CBCBCB;
        border-radius: 5px;

        &:not(:last-child) {
          margin-bottom: ${responsiveSize === "phone" ? "20px" : "40px"};
        }
  `}
`);

export default Panel;
