import FS from "./FetchService";
import _ from "lodash";

export default class SettingsService {
  static async getSettings() {
    return await FS.get("/settings");
  }

  static async saveSettings({ modified, ...data }) {
    const form = new FormData();

    if (Object.keys(modified).length > 0) {
      _.forEach(modified, (val, key) => {
        form.append(key, data[key] || "");
      });

      return await FS.post("/settings", form);
    }
  }
}
