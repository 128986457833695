import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import update from "immutability-helper";
import { groupBy, find } from "lodash";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";

import ShopService from "services/ShopService";
import LoginService from "services/LoginService";

import { AccountContext } from "./AccountContext";

const REFRESH_TIMEOUT = 15000; // 15 seconds

export const ShopContext = createContext({});

export const ShopProvider = (props) => {
  const paymentFormRef = useRef();

  const { isLoggedIn, subscription, updateSubscription, setSessionToken } =
    useContext(AccountContext);

  const history = useHistory();
  const location = useLocation();
  const { returnTo = "/account", renewCurrentBundle = false } =
    location.state || {};

  const [cachedOptions, setOptions] = useState({
    bundles: null,
    paymentTypes: null,
    paymentTypesByCurrency: null,
  });

  const [state, setState] = useState({
    selectedBundle: null,
    selectedCurrency: null,
    selectedPaymentType: null,
    paymentInfo: {},
    appliedCoupon: null,
    bundlePreview: null,
    receiptOrder: null,
    signupInfo: {},
    confirmations: {
      payment: false,
      terms: false,
    },
    errors: {},
    prevent_submit: false,
  });

  const currentOrder =
    state.bundlePreview || state.receiptOrder || state.selectedBundle;

  const isUpgrade = !props.receiptId && subscription?.status === "active";

  const isRenewal =
    subscription &&
    subscription.status === "active" &&
    subscription.expiry_notification?.should_notify &&
    subscription.product_id === state.selectedBundle?.product_id;

  const receiptMessage = useMemo(() => {
    if (!state.receiptOrder || !state.receiptOrder.receipt_message) return "";

    const message = state.receiptOrder.receipt_message;

    let retVal = message.split(/(\n|\{home_page\}|\{redirect .+?:.+?\})/g);

    for (let i = 0; i < retVal.length; i++) {
      let redirectMatch;

      if (retVal[i] === "\n") {
        retVal[i] = <br key={i} />;
      } else if (retVal[i] === "{home_page}") {
        retVal[i] = (
          <Link to='/' key={i}>
            home page
          </Link>
        );
      } else if (
        (redirectMatch = retVal[i].match(/\{redirect (.+?):(.+?)\}/))
      ) {
        retVal[i] = (
          <a href={redirectMatch[2]} key={i}>
            {redirectMatch[1]}
          </a>
        );
      }
    }
    return retVal;
  }, [state.receiptOrder]);

  // Load options on mount
  const loadBundleOptions = async () => {
    try {
      setOptions((orig) => update(orig, { bundles: { $set: null } }));

      const bundles = await ShopService.getBundles();

      const groupedBundles = groupBy(bundles, "duration");

      setOptions((orig) => update(orig, { bundles: { $set: groupedBundles } }));
    } catch (e) {
      console.error("No data returned:", e.message);
    }
  };

  const canCheckout =
    state.confirmations.payment &&
    state.confirmations.terms &&
    !state.prevent_submit;

  const updateOrder = (order) => {
    if (!order?.errors) {
      setState((orig) => ({ ...orig, order, promise: null }));
      updateSubscription(order);
    } else {
      setState((orig) => ({ ...orig, promise: null }));
    }
  };

  const handleSignup = async (paymentInfo) => {
    let redirecting = false;

    try {
      const result = await LoginService.signUp(
        {
          ...state.signupInfo,
          bundle: state.selectedBundle,
          payment: {
            type: state.selectedPaymentType.name,
            info: paymentInfo,
            coupon: state.appliedCoupon,
          },
        },
        state.selectedCurrency
      );
      if (result) {
        if (!result.errors && result.access_token) {
          redirecting = true;
          setSessionToken(result.access_token, "/account/receipt");
        } else {
          setState((orig) =>
            update(orig, {
              errors: {
                signupInfo: {
                  $set: result.errors,
                },
                global: {
                  $set: result.message,
                },
              },
            })
          );
        }
      }
    } catch (e) {
      setState((orig) =>
        update(orig, {
          errors: {
            signupInfo: {
              $set: { global: "Something went wrong. Please try again later." },
            },
          },
        })
      );
      console.error("No data returned:", e.message);
    } finally {
      if (!redirecting) {
        setState((orig) => ({ ...orig, prevent_submit: false }));
      }
    }
  };

  const handlePurchase = async (paymentInfo) => {
    try {
      const order = await ShopService.makePurchase(state.selectedBundle, {
        info: paymentInfo,
        type: state.selectedPaymentType?.name,
        coupon: state.appliedCoupon,
      });
      if (order) {
        if (order.errors) {
          setState((orig) => ({
            ...orig,
            prevent_submit: false,
            errors: { checkout: order.errors },
          }));
        } else {
          updateOrder(order);
          history.push(`/account/receipt/${order.product_order_id}`);
        }
      }
    } catch (e) {
      setState((orig) =>
        update(orig, {
          errors: {
            checkout: {
              $set: { global: "Something went wrong. Please try again later." },
            },
          },
        })
      );
      console.error("No data returned:", e.message);
    }
  };

  const handleUpgrade = async (paymentInfo) => {
    try {
      const order = await ShopService.purchaseUpgrade(state.selectedBundle, {
        info: paymentInfo,
        type: state.selectedPaymentType?.name,
        token: state.bundlePreview?.token,
        coupon: state.appliedCoupon,
      });
      if (order) {
        if (order.errors) {
          setState((orig) => ({
            ...orig,
            prevent_submit: false,
            errors: { checkout: order.errors },
          }));
        } else {
          if (
            order.status !== "failed" ||
            !subscription ||
            subscription.status === "expired"
          ) {
            updateOrder(order);
          }
          if (order.renewal_type === "m" || order.status === "failed") {
            history.push(`/account/receipt/${order.product_order_id}`);
            return;
          }
          history.push(returnTo, { upgradeSuccess: true });
        }
      }
    } catch (e) {
      setState((orig) =>
        update(orig, {
          errors: {
            checkout: {
              $set: { global: "Something went wrong. Please try again later." },
            },
          },
        })
      );
      console.error("No data returned:", e.message);
    }
  };

  const toggleConfirmation = (key) =>
    setState((orig) => update(orig, { confirmations: { $toggle: [key] } }));

  const setters = {
    setSelectedBundle: (selectedBundle) =>
      setState((orig) =>
        update(orig, { selectedBundle: { $set: selectedBundle } })
      ),
    setSelectedCurrency: (selectedCurrency) => {
      setState((orig) =>
        update(orig, { selectedCurrency: { $set: selectedCurrency } })
      );
    },
    setPaymentType: useCallback(
      (selectedPaymentType) =>
        setState((orig) =>
          update(orig, {
            selectedPaymentType: { $set: selectedPaymentType },
            paymentInfo: { $set: {} },
          })
        ),
      [setState]
    ),
    setPaymentInfo: (paymentInfo) =>
      setState((orig) => update(orig, { paymentInfo: { $set: paymentInfo } })),
    setSignupInfo: useCallback(
      (signupInfo) =>
        setState((orig) => update(orig, { signupInfo: { $set: signupInfo } })),
      [setState]
    ),
    toggleConfirmPayment: () => toggleConfirmation("payment"),
    toggleConfirmTerms: () => toggleConfirmation("terms"),
  };

  const getPaymentInfo = async (intentType = null) => {
    let { paymentInfo } = state;

    if (paymentFormRef.current?.beforeSubmit) {
      paymentInfo = await paymentFormRef.current.beforeSubmit(intentType);
    }

    if (!paymentInfo || paymentInfo.errors) {
      if (paymentInfo.errors) {
        setState((orig) =>
          update(orig, {
            errors: { paymentInfo: { $set: paymentInfo.errors } },
            prevent_submit: { $set: false },
          })
        );
      }
      return false;
    }

    return paymentInfo;
  };

  const loadUpgradePreview = async () => {
    const preview = await ShopService.getUpgradePreview(
      state.selectedBundle.product_id
    );
    setState((orig) => update(orig, { bundlePreview: { $set: preview } }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canCheckout) return;

    setState((orig) => update(orig, { prevent_submit: { $set: true } }));
    const paymentInfo = await getPaymentInfo();

    if (!paymentInfo) return;

    if (isLoggedIn() && isUpgrade) {
      handleUpgrade(paymentInfo);
    } else {
      if (!isLoggedIn()) {
        handleSignup(paymentInfo);
      } else {
        handlePurchase(paymentInfo);
      }
    }
  };

  const handleApplyCoupon = async (coupon) => {
    setState((orig) => update(orig, { prevent_submit: { $set: true } }));

    const result = await ShopService.applyCoupon(
      state.selectedBundle,
      coupon,
      state.selectedPaymentType.name
    );
    const { preview, applied_coupon: appliedCoupon, success } = result;

    if (success) {
      setState((orig) =>
        update(orig, {
          bundlePreview: { $set: preview },
          appliedCoupon: { $set: appliedCoupon },
          prevent_submit: { $set: false },
          errors: { $unset: ["coupon"] },
        })
      );
    } else {
      setState((orig) =>
        update(orig, {
          bundlePreview: { $set: null },
          appliedCoupon: { $set: null },
          prevent_submit: { $set: false },
          errors: { coupon: { $set: "This is not a valid coupon code" } },
        })
      );
    }
  };

  const handleFixPayment = async () => {
    if (state.prevent_submit) return;

    setState((orig) => update(orig, { prevent_submit: { $set: true } }));

    const paymentInfo = await getPaymentInfo("payment");

    if (!paymentInfo) return;

    const result = await ShopService.updatePaymentMethod(paymentInfo, true);

    if (result?.product_order_id) {
      if (
        !subscription ||
        result.product_order_id >= subscription.product_order_id
      ) {
        updateSubscription(result);
        history.replace(`/account/receipt/${result.product_order_id}`);
        return;
      }
    }

    setState((orig) => update(orig, { prevent_submit: { $set: false } }));
  };

  const loadPaymentTypeOptions = async () => {
    const paymentTypes = await ShopService.getAvailablePaymentMethods();
    if (paymentTypes) {
      let paymentTypesByCurrency = {};
      let selectedCurrency = { code: "", sorting: Infinity };

      for (const paymentOption of paymentTypes) {
        for (const currency of paymentOption.currencies) {
          if (!paymentTypesByCurrency[currency.code]) {
            if (currency.sorting < selectedCurrency.sorting) {
              selectedCurrency.code = currency.code;
              selectedCurrency.sorting = currency.sorting;
            }

            paymentTypesByCurrency = update(paymentTypesByCurrency, {
              [currency.code]: {
                $set: {
                  sorting: currency.sorting,
                  options: [paymentOption],
                },
              },
            });
          } else {
            paymentTypesByCurrency = update(paymentTypesByCurrency, {
              [currency.code]: { options: { $push: [paymentOption] } },
            });
          }
        }
      }

      setState((orig) => ({
        ...orig,
        selectedCurrency: selectedCurrency.code,
      }));
      setOptions((orig) => ({
        ...orig,
        paymentTypesByCurrency,
        paymentTypes: paymentTypesByCurrency[selectedCurrency.code]?.options,
      }));
    }
  };

  const loadReceipt = useCallback(
    async (receiptId) => {
      try {
        const receiptOrder = await ShopService.getReceipt(receiptId);
        setState((orig) =>
          update(orig, {
            receiptOrder: { $set: receiptOrder },
            selectedPaymentType: {
              $set: find(
                cachedOptions.paymentTypes,
                (type) => type.name === receiptOrder.payment_method
              ),
            },
          })
        );

        if (receiptOrder.product_order_id === subscription?.product_order_id) {
          updateSubscription(receiptOrder);
        }
      } catch (e) {
        setState((orig) =>
          update(orig, {
            errors: {
              receiptView: {
                $set: "Something went wrong. Please try again later.",
              },
            },
          })
        );
      }
    },
    [cachedOptions.paymentTypes]
  );

  useEffect(() => {
    if (state.receiptOrder && state.receiptOrder.status !== "active") {
      const timeout = setInterval(() => {
        loadReceipt(state.receiptOrder.product_order_id);
      }, REFRESH_TIMEOUT);

      return () => clearInterval(timeout);
    }
  });
  useEffect(() => {
    if (props.receiptId && cachedOptions.paymentTypes) {
      loadReceipt(props.receiptId);
    }
  }, [props.receiptId, cachedOptions.paymentTypes, loadReceipt]);

  useEffect(() => {
    if (state.selectedCurrency && cachedOptions.paymentTypesByCurrency)
      setOptions((orig) =>
        update(orig, {
          paymentTypes: {
            $set: cachedOptions.paymentTypesByCurrency[state.selectedCurrency]
              .options,
          },
        })
      );
  }, [state.selectedCurrency, cachedOptions.paymentTypesByCurrency]);

  // useEffect(() => {
  //   ShopService.currency = state.selectedCurrency;
  //   loadBundleOptions();
  // }, [state.selectedCurrency]);

  useEffect(() => {
    loadPaymentTypeOptions();
  }, []);

  useEffect(() => {
    if (!props.receiptId && state.selectedCurrency) {
      ShopService.currency = state.selectedCurrency;
      loadBundleOptions();
    }
  }, [props.receiptId, state.selectedCurrency]);

  useEffect(() => {
    if (!cachedOptions.bundles || cachedOptions.paymentTypes) return;

    if (renewCurrentBundle && subscription && !state.selectedBundle) {
      setState((orig) =>
        update(orig, {
          selectedBundle: {
            $set: find(
              cachedOptions.bundles,
              (bundle) => bundle.product_id === subscription.product_id
            ),
          },
          selectedPaymentType: {
            $set: find(
              cachedOptions.paymentTypes,
              (type) => type.name === subscription.payment_method
            ),
          },
        })
      );
    }
  }, [
    renewCurrentBundle,
    subscription,
    state.selectedBundle,
    cachedOptions.bundles,
    cachedOptions.paymentTypes,
  ]);

  useEffect(() => {
    if (isUpgrade && state.selectedBundle !== null) {
      setState((orig) => update(orig, { bundlePreview: { $set: null } }));
      loadUpgradePreview();
    }
  }, [isUpgrade, state.selectedBundle]);

  return (
    <ShopContext.Provider
      value={{
        options: cachedOptions,
        ...state,
        ...setters,
        currentOrder,
        receiptMessage,
        isUpgrade,
        isRenewal,
        isReceipt: !!props.receiptId,
        canCheckout,
        handleSubmit,
        handleApplyCoupon,
        handleFixPayment,
        paymentFormRef,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};
