import React, { useState } from "react";

export const NavigationContext = React.createContext();

export const NavigationProvider = ({ children }) => {
  const [backArrowFunc, setBackArrowFunc] = useState(null);

  return (
    <NavigationContext.Provider value={{ backArrowFunc, setBackArrowFunc }}>
      {children}
    </NavigationContext.Provider>
  );
};
