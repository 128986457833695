import styled from "styled-components";

const Form = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const FormError = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  font-size: 12px;
  width: 100%;
  color: red;
  position: absolute;
  top: 100%;
`;

export default Form;
