import React from "react";
import { compose } from "recompose";
import styled from "styled-components";

import Grid from "@material-ui/core/Grid";
import Panel from "components/AccountView/Input/Panel";
import Button from "components/Input/Button";
import TextInput from "components/Input/TextInput";
import LoadingContainer from "components/Loading/LoadingContainer";
import withTheme from "composers/withTheme";
import AccountService from "services/AccountService";

const Form = compose(withTheme)(styled.form`
  width: 100%;
  display: grid;
  grid-auto-rows: minmax(1em, auto);
  row-gap: 20px;
  ${({ responsiveSize }) =>
    responsiveSize !== "phone" &&
    `
    grid-template-columns: 1fr auto;
    column-gap: 25px;
    justify-content: space-between;
  `}
`);

const InputWrapper = compose(withTheme)(styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(
    ${({ responsiveSize }) => (responsiveSize === "phone" ? 1 : 2)},
    1fr
  );
  column-gap: 20px;
  row-gap: 15px;
  padding-left: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "15px" : "30px"};
  ${({ responsiveSize }) =>
    responsiveSize === "phone" && "margin-bottom: 20px;"}
`);

const SuccessMessage = compose(withTheme)(styled.div`
  text-align: center;
  padding-left: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "0" : "30px"};
  color: green;
`);

class ManagePassword extends React.Component {
  constructor() {
    super();

    this.state = {
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
      error: "",
      success: "",
      prevent_submit: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.changePassword();
  };

  async changePassword() {
    if (!this.state.prevent_submit) {
      this.setState({ prevent_submit: true, success: "" });
      try {
        const result = await AccountService.changePassword(
          this.state.current_password,
          this.state.new_password,
          this.state.new_password_confirmation
        );

        if (result) {
          if (!result.errors) {
            this.setState({
              error: "",
              current_password: "",
              new_password: "",
              new_password_confirmation: "",
              success: "Your password has been successfully updated",
            });
          } else {
            this.setState({ error: result.errors });
          }
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      } finally {
        this.setState({ prevent_submit: false });
      }
    }
  }

  render() {
    const { responsiveSize } = this.props;

    return (
      <Panel
        style={{ paddingBottom: "10px", ...this.props.style }}
        invisible={responsiveSize === "phone" ? 1 : 0}
      >
        {this.state.prevent_submit && <LoadingContainer />}
        <Form onSubmit={this.handleSubmit}>
          <Grid container spacing={3}>
            <h3>Change Password</h3>
            <InputWrapper>
              <TextInput
                autoComplete="current_password"
                label="Current Password*"
                onChange={this.handleChange}
                type="password"
                id="current_password"
                name="current_password"
                value={this.state.current_password}
                error={this.state.error?.current_password}
                errorMessage={this.state.error?.current_password}
              />
              <TextInput
                autoComplete="new_password"
                style={
                  responsiveSize !== "phone" ? { gridRowStart: "2" } : null
                }
                label="New Password*"
                onChange={this.handleChange}
                type="password"
                id="new_password"
                name="new_password"
                value={this.state.new_password}
                error={this.state.error?.new_password}
                errorMessage={this.state.error?.new_password}
              />
              <TextInput
                autoComplete="new_password_confirmation"
                style={
                  responsiveSize !== "phone" ? { gridRowStart: "2" } : null
                }
                label="Retype New Password*"
                onChange={this.handleChange}
                type="password"
                id="retype_new_password"
                name="new_password_confirmation"
                value={this.state.new_password_confirmation}
                error={this.state.error?.new_password_confirmation}
                errorMessage={this.state.error?.new_password_confirmation}
              />
            </InputWrapper>
          </Grid>
          <Grid container justify="flex-end" alignItems="flex-start">
            <Button type="submit">Save</Button>
          </Grid>
          <SuccessMessage>{this.state.success}</SuccessMessage>
        </Form>
      </Panel>
    );
  }
}

export default compose(withTheme)(ManagePassword);
