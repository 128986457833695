import React from "react";

import MaterialButton from "@material-ui/core/Button";

export default function Button(props) {
  const { children, color, icon, size, variant, component, ..._props } = props;

  const Component = component || MaterialButton;

  return (
    <Component
      variant={variant ? variant : "contained"}
      color={color || "primary"}
      size={size ? size : "medium"}
      {..._props}
    >
      {children}
    </Component>
  );
}
