/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useState, useEffect } from "react";
import { compose } from "recompose";
import styled from "styled-components";

import Grid from "@material-ui/core/Grid";

import Panel from "components/AccountView/Input/Panel";
import _Avatar from "components/AccountView/Student/Avatar";
import AvatarPicker from "components/AccountView/Student/AvatarPicker";
import Button from "components/Input/Button";
import Dropdown from "components/Input/Dropdown";
import TextInput from "components/Input/TextInput";
import LoadingContainer from "components/Loading/LoadingContainer";
import withTheme from "composers/withTheme";
import RegionService from "services/RegionService";
import StudentService from "services/StudentService";

import { H2 } from "components/AccountView/Headings";
import { NavigationContext } from "context/NavigationContext";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

const Form = compose(withTheme)(styled.form`
  width: 100%;
  display: flex;
  justify-content: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "initial" : "space-between"};
  flex-direction: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "column" : "row"};
`);

const Container = compose(withTheme)(styled.div`
  position: relative;
  width: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "100%" : "412px"};
  padding-top: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "74px" : "0"};
  padding-right: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "0" : "48px"};
  padding-left: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "0" : "48px"};
  box-sizing: content-box;
`);

const Avatar = compose(withTheme)(styled(_Avatar)`
  position: absolute;
  width: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "58px" : "64px"};
  background-color: #fbb74d;
  cursor: pointer;
`);

const NewOrEditStudent = (props) => {
  const { setBackArrowFunc } = useContext(NavigationContext);

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    school: "",
    gender: "",
    age: 0,
    grade: "",
    region: "",
    grade_options: [],
    region_options: [],
    gender_options: [
      { value: "m", label: "Male" },
      { value: "f", label: "Female" },
      { value: "o", label: "Prefer not to Say" },
    ],
    avatar: `{"icon": "null", "color": "#FCB034"}`,
    avatar_icon: "",
    avatar_color: "",
    error: "",
    prevent_submit: true,
    loaded: [],
    fully_loaded: false,
    show_avatar_picker: false,
  });

  const isEdit = !!props.match.params.student_id;
  const require = ["grades", "regions"];
  if (isEdit) {
    require.push("student_data");
  }

  const setLoaded = (value) => {
    setState((orig) => {
      const loaded = [...orig.loaded, value];

      const newState = { ...orig, loaded };

      if (require.every((item) => loaded.includes(item))) {
        newState.prevent_submit = false;
        newState.fully_loaded = true;
      }

      return newState;
    });
  };

  const loadGrades = async () => {
    try {
      const grades = await StudentService.getAvailableGrades();
      const grade_options = grades.map((grade) => ({ value: grade }));

      setState((orig) => ({ ...orig, grade_options: grade_options }));
      setLoaded("grades");
    } catch (e) {
      console.error("No data returned:", e.message);
    }
  };

  const loadRegions = async () => {
    try {
      const regions = await RegionService.getRegions();
      const region_options = regions.map(({ region_id, code, name }) => ({
        value: region_id,
        label: `${code ? `${code} - ` : ""}${name}`,
      }));

      setState((orig) => ({ ...orig, region_options: region_options }));
      setLoaded("regions");
    } catch (e) {
      console.error("No data returned:", e.message);
    }
  };

  const loadStudent = async (student_id) => {
    try {
      const student = await StudentService.getOne(student_id);
      student.region = student.region_id;
      let avatarIcon = null;
      let avatarColor = null;
      try {
        const parsedAvatarJSON = JSON.parse(student.avatar);
        avatarIcon = parsedAvatarJSON.icon;
        avatarColor = parsedAvatarJSON.color;
      } catch (e) {
        console.error("Avatar JSON parsing failed", e.message);
      }

      setState((orig) => ({
        ...orig,
        avatar_icon: avatarIcon,
        avatar_color: avatarColor,
        ...student,
      }));
      setLoaded("student_data");
    } catch (e) {
      console.error("No data returned:", e.message);
    }
  };

  const loadData = () => {
    loadGrades();
    loadRegions();

    const { student_id } = props.match.params;
    if (student_id) {
      loadStudent(student_id);
    }
  };

  const isLoaded = (value) => {
    return state.loaded.includes(value);
  };

  const mount = () => {
    loadData();
    setBackArrowFunc(() => () => props.goBack());

    return () => {
      setBackArrowFunc(null);
    };
  };
  useEffect(mount, []);

  const createOrUpdateStudent = async () => {
    if (!state.prevent_submit) {
      setState((orig) => ({ ...orig, prevent_submit: true }));
      try {
        let result = {};
        if (props.match.params.student_id) {
          result = await StudentService.update(state);
        } else {
          result = await StudentService.create(state);
        }

        if (!result.errors) {
          props.onSuccess && props.onSuccess();
        } else {
          setState((orig) => ({ ...orig, error: result.errors }));
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      } finally {
        setState((orig) => ({ ...orig, prevent_submit: false }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createOrUpdateStudent();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((orig) => ({ ...orig, [name]: value }));
  };

  const showAvatarPicker = () => {
    setState((orig) => ({
      ...orig,
      show_avatar_picker: true,
    }));
  };

  const closeAvatarPicker = (avatarPickerState) => {
    setState((orig) => {
      const newState = { ...orig, show_avatar_picker: false };
      if (avatarPickerState) {
        let avatarJSONString = "";

        try {
          avatarJSONString = JSON.stringify(
            JSON.parse(
              `{"icon": "${avatarPickerState.icon}", "color": "${avatarPickerState.color}"}`
            )
          );
        } catch (e) {
          console.error("Avatar JSON parsing failed", e.message);
        }

        newState.avatar = avatarJSONString;
        newState.avatar_icon = avatarPickerState.icon;
        newState.avatar_color = avatarPickerState.color;
      }
      return newState;
    });
  };

  const { responsiveSize } = props;

  return (
    <div>
      {responsiveSize !== "phone" && <H2>{isEdit ? "Edit" : "Add"} Student</H2>}
      <Panel invisible={responsiveSize === "phone" ? 1 : 0}>
        {(!state.fully_loaded || state.prevent_submit) && <LoadingContainer />}
        {state.show_avatar_picker && state.fully_loaded ? (
          <AvatarPicker
            icon={state.avatar_icon}
            color={state.avatar_color || "#104F66"}
            onClose={closeAvatarPicker}
          />
        ) : (
          <Form onSubmit={handleSubmit}>
            <Container>
              <Grid
                container
                style={{ height: "70px" }}
                justify="center"
                alignItems="center"
              >
                <Avatar
                  onClick={showAvatarPicker}
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      showAvatarPicker();
                    }
                  }}
                  showPlus={
                    state.avatar_icon === "" || state.avatar_icon === "null"
                  }
                  contentsStyle={{
                    fontSize: "2.5rem",
                    fontWeight: 600,
                    color: "white",
                  }}
                  color={state.avatar_color}
                  icon={state.avatar_icon}
                ></Avatar>
              </Grid>
              <Grid>
                <p
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={showAvatarPicker}
                  tabIndex={-1}
                >
                  {`${
                    state.avatar_icon === "" || state.avatar_icon === "null"
                      ? "Add"
                      : "Change"
                  }`}{" "}
                  Profile Photo
                </p>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={2}
                justify="space-between"
                style={{ marginTop: "1.5rem" }}
              >
                <Grid item xs={12} md={6}>
                  <TextInput
                    label="First Name*"
                    onChange={handleChange}
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={state.first_name || ""}
                    error={state.error?.first_name}
                    errorMessage={state.error?.first_name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    label="Last Name*"
                    onChange={handleChange}
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={state.last_name || ""}
                    error={state.error?.last_name}
                    errorMessage={state.error?.last_name}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Dropdown
                    label="Gender*"
                    id="gender"
                    name="gender"
                    onChange={handleChange}
                    value={state.gender}
                    error={state.error?.gender}
                    errorMessage={state.error?.gender}
                    options={state.gender_options}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    type="number"
                    min="1"
                    max="120"
                    label="Age"
                    id="age"
                    name="age"
                    onChange={handleChange}
                    value={state.age || ""}
                    error={state.error?.age}
                    errorMessage={state.error?.age}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Dropdown
                    label="Grade*"
                    id="grade"
                    name="grade"
                    loading={!isLoaded("grades")}
                    onChange={handleChange}
                    value={state.grade}
                    error={state.error?.grade}
                    errorMessage={state.error?.grade}
                    options={state.grade_options}
                  />
                </Grid>
                {responsiveSize !== "phone" && <div />}
                <Grid item xs={12} md={6}>
                  <TextInput
                    label="School Name"
                    onChange={handleChange}
                    type="text"
                    id="school"
                    name="school"
                    value={state.school || ""}
                    error={state.error?.school}
                    errorMessage={state.error?.school}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Dropdown
                    nullable
                    nullValue="None"
                    label="School Region"
                    id="region"
                    loading={!isLoaded("regions")}
                    name="region"
                    onChange={handleChange}
                    value={state.region}
                    error={state.error?.region}
                    errorMessage={state.error?.region}
                    options={state.region_options}
                  />
                </Grid>
              </Grid>
              <Grid
                direction="row"
                container
                justify="flex-end"
                alignItems="center"
                style={{ marginTop: "1.5rem" }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => props.history.goBack()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item style={{ margin: "0 0 0 2rem" }}>
                  <Button type="submit">
                    {isEdit ? "Update" : "Register"}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Form>
        )}
      </Panel>
    </div>
  );
};

export default connect(null, (dispatch) => ({
  goBack: () => dispatch(goBack()),
}))(compose(withTheme)(NewOrEditStudent));
