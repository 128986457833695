import FS from "./FetchService";

export default class CurrencyService {
  static async getCurrencies() {
    return await FS.get("/currencies");
  }

  static async addCurrency(code, label, exchange_rate, sorting) {
    const form = new FormData();
    form.append("code", code || "");
    form.append("label", label || "");
    form.append("exchange_rate", exchange_rate);
    form.append("sorting", sorting || 0);

    return await FS.post(`/currencies`, form);
  }

  static async saveCurrency(currency_id, code, label, exchange_rate, sorting) {
    const form = new FormData();
    form.append("code", code || "");
    form.append("label", label || "");
    form.append("exchange_rate", exchange_rate);
    form.append("sorting", sorting || 0);

    return await FS.patch(`/currencies/${currency_id}`, form);
  }

  static async deleteCurrency(currency_id) {
    return await FS.delete(`/currencies/${currency_id}`);
  }

  static async deleteAll() {
    return await FS.delete(`/currencies`);
  }
}
