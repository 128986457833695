import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { AccountContext } from "context/AccountContext";
import Button from "components/Input/Button";

// Used to notify when the subscription is about to, or has, failed
const SubscriptionStatusNotification = (props) => {
  const { subscription } = useContext(AccountContext);
  const history = useHistory();

  const { clickAway = false } = props;

  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  const handleRenew = () => {
    history.push("/account/bundles", {
      returnTo: "/",
      renewCurrentBundle: true,
    });
    setOpen(false);
  };

  const isSubscriptionExpired = subscription?.status === "expired";

  if (
    !(isSubscriptionExpired || subscription?.expiry_notification?.should_notify)
  ) {
    return null;
  }

  const text = isSubscriptionExpired
    ? "Your subscription has expired."
    : `Your subscription will expire in ${subscription.expiry_notification.days_remaining} days.`;

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={!clickAway}>
      <DialogTitle>Notice</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        {!isSubscriptionExpired && (
          <Button color='secondary' onClick={handleClose}>
            Close
          </Button>
        )}
        <Button color='primary' onClick={handleRenew}>
          Renew
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionStatusNotification;
