import FS from "./FetchService";

export default class AppService {
  static async getAppSettings() {
    return await FS.get("/app");
  }

  static async saveFrontendCustomization({
    hide_sidebar,
    sidebar_title,
    sidebar_lines,
    sidebar_footer_url,
    sidebar_footer,
  }) {
    const obj = {
      hide_sidebar,
      sidebar_title,
      sidebar_lines,
      sidebar_footer_url,
      sidebar_footer,
    };

    const data = JSON.stringify(obj);
    return await FS.post("/customizations", data, {
      header: (headers) => headers.append("Content-Type", "application/json"),
    });
  }
}
