import styled from "styled-components";

export const H2 = styled.h2`
  user-select: none;
  text-align: center;
  margin-top: 0;
  font-size: 32px;
  line-height: 41px;
  font-weight: bold;
  font-style: normal;
`;

export const H3 = styled.h3`
  margin-top: 0;
  font-weight: bold;
  text-align: center;
`;
