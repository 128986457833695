import React, { useContext } from "react";

import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/School";
import GearIcon from "@material-ui/icons/Settings";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import ContactIcon from "@material-ui/icons/ContactSupport";
import FeedbackIcon from "@material-ui/icons/Feedback";

import { AppContext } from "context/AppContext";
import { AccountContext } from "context/AccountContext";
import ResponsiveNavbar from "components/Navigation/ResponsiveNavbar";

const feedbackLink = "https://forms.gle/cswPorL3ZcS7ezxe7";
const contactLink = "https://forms.gle/9Ldb6Q6kaN3b6gWbA";

const AccountNavbar = (props) => {
  const { style } = useContext(AppContext);
  const { subscription } = useContext(AccountContext);

  const activeSubscription =
    style === "trial" || subscription?.status === "active";

  const links = [
    {
      to: "/home",
      label: "Home",
      icon: <HomeIcon />,
      exact: true,
      disabled: !activeSubscription,
    },
    {
      to: "/account/students",
      label: "Manage Students",
      icon: <SchoolIcon />,
      disabled: !activeSubscription,
    },
    {
      to: "/account",
      label: "Account Settings",
      icon: <GearIcon />,
    },
    {
      label: "Feedback",
      menuItems: [
        {
          to: feedbackLink,
          label: "Give Feedback",
          icon: <FeedbackIcon />,
        },
        {
          to: contactLink,
          label: "Contact Support",
          icon: <ContactIcon />,
        },
      ],
    },
    {
      type: "logout",
      label: "Logout",
      icon: <LogoutIcon />,
    },
  ];

  return <ResponsiveNavbar {...props} links={links} />;
};

export default AccountNavbar;
