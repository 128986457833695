import React, { useContext, useState } from "react";

import { AccountContext } from "context/AccountContext";
import { H2, H3 } from "components/LoginView/Headings";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Button from "components/Input/Button";
import FlexButtonGroup from "components/Layout/FlexButtonGroup";

import { goBack } from "connected-react-router";
import { connect } from "react-redux";
import ShopService from "services/ShopService";
import LoadingContainer from "components/Loading/LoadingContainer";
import { ReadableDate } from "helpers/DateFormatter";

const confirmationText = "I understand";

const RenewSubscriptionView = (props) => {
  const { subscription, updateSubscription } = useContext(AccountContext) || {};

  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirm = (e) => {
    setConfirm((orig) => !orig);
  };

  const handleBack = (e) => {
    props.goBack();
  };

  const handleCancel = async (e) => {
    if (loading) return;

    setLoading(true);
    const updatedSubscription = await ShopService.renewStripePlan();

    if (subscription) {
      updateSubscription(updatedSubscription);
      props.goBack();
    } else {
      setLoading(false);
    }
  };

  if (!subscription) {
    return null;
  }

  return (
    <React.Fragment>
      {loading && <LoadingContainer />}
      <H2>Thank you for renewing your plan</H2>
      <H3 style={{ textAlign: "left" }}>What happens next</H3>
      <ul>
        <li>
          You will be automatically billed to renew your subscription on{" "}
          {ReadableDate(subscription.expires_at)}
        </li>
      </ul>
      <FormControlLabel
        style={{ display: "flex", justifyContent: "center" }}
        control={
          <Checkbox
            id='confirmUpgrade'
            color='primary'
            onClick={handleConfirm}
            checked={confirm}
          />
        }
        label={confirmationText}
      />
      <FlexButtonGroup justifyContent='center'>
        <Button color='secondary' onClick={handleBack} disabled={loading}>
          Go Back
        </Button>
        <Button onClick={handleCancel} disabled={!confirm || loading}>
          Renew Plan
        </Button>
      </FlexButtonGroup>
    </React.Fragment>
  );
};

export default connect(null, (dispatch) => ({
  goBack: () => dispatch(goBack()),
}))(RenewSubscriptionView);
