import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Button from "components/Input/Button";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import LoadingContainer from "components/Loading/LoadingContainer";
import _Form from "components/LoginView/Input/Form";
import { push, replace } from "connected-react-router";
import LoginService from "services/LoginService";

import LockIcon from "@material-ui/icons/Lock";

import { AccountContext } from "context/AccountContext";

const Form = styled(_Form)`
  max-width: 250px;
  margin-top: 30px;
`;

const Step3 = (props) => {
  const { setSessionToken } = useContext(AccountContext);

  const verification = sessionStorage.getItem("pw2_verification");
  if (!verification) {
    props.replace(`${props.basePath}/2`);
  }

  const [state, setState] = useState({
    password: "",
    password_confirmation: "",
    verification: verification,
    success: "",
    token: "",
    error: "",
    prevent_submit: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const changePassword = async () => {
    if (!state.prevent_submit) {
      setState((orig) => ({ ...orig, prevent_submit: true }));
      try {
        const result = await LoginService.changePassword(
          state.verification,
          state.password,
          state.password_confirmation
        );
        if (!result.errors && result.login_token) {
          setState((orig) => ({
            ...orig,
            success: true,
            token: result.login_token,
          }));
        } else {
          setState((orig) => ({
            ...orig,
            error: { ...result.errors },
            global: result.message,
          }));
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      } finally {
        setState((orig) => ({ ...orig, prevent_submit: false }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword();
  };

  const handleLogin = () => {
    if (state.success && state.token) {
      setSessionToken(state.token);
    }
  };

  return (
    <React.Fragment>
      {state.prevent_submit && <LoadingContainer />}
      <h3>Success!</h3>
      {!state.success ? (
        <React.Fragment>
          <div>Please reset your password</div>
          <Form onSubmit={handleSubmit}>
            <Field>
              <TextInput
                errorColor="red"
                icon={<LockIcon />}
                placeholder="New Password"
                type="password"
                id="password"
                name="password"
                onChange={handleChange}
                value={state.password}
                error={!!state.error?.password}
                errorMessage={state.error?.password}
              />
            </Field>
            <Field>
              <TextInput
                errorColor="red"
                icon={<LockIcon />}
                placeholder="Confirm New Password"
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                onChange={handleChange}
                value={state.password_confirmation}
                error={!!state.error?.password_confirmation}
                errorMessage={state.error?.password_confirmation}
              />
            </Field>
            <Field>
              <Button green disabled={state.prevent_submit} type="submit">
                Submit
              </Button>
            </Field>
          </Form>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div>
            Your password has been successfully reset!
            <br />
            Please click here to login
          </div>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Field>
              <Button green type="button" onClick={handleLogin}>
                Login
              </Button>
            </Field>
          </Form>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default connect(null, (dispatch) => ({
  push: (url) => dispatch(push(url)),
  replace: (url) => dispatch(replace(url)),
}))(Step3);
