import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Elements as Stripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ShopService from "services/ShopService";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import Field from "components/Layout/Field";
import TextInput from "components/Input/TextInput";
import StripeInput from "components/Input/StripeInput";

import PaymentIcon from "@material-ui/icons/Payment";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PoundIcon from "mdi-material-ui/Pound";
import LoadingContainer from "components/Loading/LoadingContainer";
import { ShopContext } from "context/ShopContext";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const NewPaymentMethodForm = forwardRef((props, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  const { errors: shopErrors } = useContext(ShopContext);

  const [state, setState] = useState({
    name: "",
    cardnumber: false,
    cardexpiry: false,
    cardcvc: false,
    dirty: false,
  });

  const [stateErrors, _setErrors] = useState(
    typeof shopErrors === "undefined" ? {} : "controlled"
  );
  const setErrors = (errors) => {
    return _setErrors((orig) => (orig === "controlled" ? orig : errors));
  };

  const errors =
    stateErrors === "controlled" ? shopErrors.paymentInfo : stateErrors;

  const cachedClientSecret = useRef(null);
  const [loading, setLoading] = useState(false);

  const getNewClientSecret = async (intentType = null) => {
    const clientSecret =
      intentType === "payment"
        ? (await ShopService.getStripePaymentSecret())?.payment_secret
        : (await ShopService.getStripeSetupSecret())?.secret;
    cachedClientSecret.current = clientSecret;

    return clientSecret;
  };

  const validateFormCompletion = () => {
    let passed = true;
    const errors = {};

    const generateErrorMessage = (field) => `Your ${field} is incomplete`;

    if (state.name.length === 0) {
      passed = false;
      errors.name = "Your name is required";
    }
    if (!state.cardnumber) {
      passed = false;
      errors.cardnumber = generateErrorMessage("card number");
    }
    if (!state.cardexpiry) {
      passed = false;
      errors.cardexpiry = generateErrorMessage("card's expiration date");
    }
    if (!state.cardcvc) {
      passed = false;
      errors.cardcvc = generateErrorMessage("card's security code");
    }

    setErrors(errors);

    return {
      passed,
      errors,
    };
  };

  useImperativeHandle(ref, () => ({
    beforeSubmit: async (intentType = null) => {
      if (!stripe || !elements) {
        return;
      }
      const validation = validateFormCompletion();
      if (!validation.passed) {
        return { errors: validation.errors };
      }

      let clientSecret = cachedClientSecret.current;
      if (!clientSecret) {
        clientSecret = await getNewClientSecret(intentType);
      }

      let currentIntent, intentStatus;
      if (intentType === "payment") {
        currentIntent = await stripe.retrievePaymentIntent(clientSecret);
        intentStatus = currentIntent?.paymentIntent?.status;
      } else {
        currentIntent = await stripe.retrieveSetupIntent(clientSecret);
        intentStatus = currentIntent?.setupIntent?.status;
      }
      if (intentStatus === "succeeded") {
        if (state.dirty) {
          clientSecret = await getNewClientSecret(intentType);
        } else {
          return currentIntent;
        }
      }
      const cardElement = elements.getElement(CardNumberElement);
      setLoading(true);
      let errors, intent;
      if (intentType === "payment") {
        const results = await stripe.confirmCardPayment(clientSecret, {
          setup_future_usage: "on_session",
          payment_method: {
            billing_details: { name: state.name },
            card: cardElement,
          },
        });
        errors = results.error;
        intent = results.paymentIntent;
      } else {
        let results = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            billing_details: { name: state.name },
            card: cardElement,
          },
        });
        errors = results.error;
        intent = results.setupIntent;
      }
      setLoading(false);
      if (errors) {
        console.error("[error]", errors);
        return { errors };
      } else {
        return intent;
      }
    },
  }));

  //   clear: () => {
  //     if (elements) {
  //       elements.getElement(CardNumberElement).clear();
  //       elements.getElement(CardExpiryElement).clear();
  //       elements.getElement(CardCvcElement).clear();
  //     }

  //     setState({
  //       name: "",
  //       cardnumber: false,
  //       cardexpiry: false,
  //       cardcvc: false,
  //       dirty: false,
  //     });
  //   },
  // }));

  const handleChange = (e) => {
    const { name, value } = e.target || {};

    if (name) {
      setState((orig) => ({ ...orig, [name]: value, dirty: true }));
    } else {
      setState((orig) => ({ ...orig, [e.name]: e.complete, dirty: true }));
    }
  };

  const { disableLoadingSpinner } = props;
  return (
    <React.Fragment>
      {!stripe && <LoadingContainer />}
      {!disableLoadingSpinner && stripe && loading && (
        <LoadingContainer delay={0} />
      )}
      <Field>
        <TextInput
          label='Name'
          type='text'
          name='name'
          onChange={handleChange}
          value={state.name}
          error={errors?.name}
          errorMessage={errors?.name}
        />
      </Field>
      <Field>
        <TextInput
          label='Card Number'
          name='cardnumber'
          icon={<PaymentIcon />}
          onChange={(e) => handleChange({ name: "cardnumber", ...e })}
          error={errors?.cardnumber}
          errorMessage={errors?.cardnumber}
          inputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
              options: CARD_ELEMENT_OPTIONS,
            },
          }}
        />
      </Field>
      <Field>
        <TextInput
          label='Card Expires'
          name='cardexpiry'
          icon={<ScheduleIcon />}
          onChange={(e) => handleChange({ name: "cardexpiry", ...e })}
          error={errors?.cardexpiry}
          errorMessage={errors?.cardexpiry}
          inputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
              options: CARD_ELEMENT_OPTIONS,
            },
          }}
        />
      </Field>
      <Field>
        <TextInput
          label='CVC'
          name='cardcvc'
          icon={<PoundIcon />}
          onChange={(e) => handleChange({ name: "cardcvc", ...e })}
          error={errors?.cardcvc}
          errorMessage={errors?.cardcvc}
          inputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
              options: CARD_ELEMENT_OPTIONS,
            },
          }}
        />
      </Field>
    </React.Fragment>
  );
});

const AddPaymentMethodForm = forwardRef((props, ref) => {
  const [state, setState] = useState({
    stripePromise: null,
  });

  const initStripe = async () => {
    const { key } = await ShopService.getStripeKey();

    setState({
      stripePromise: loadStripe(key),
    });
  };

  const mount = () => {
    initStripe();
  };
  useEffect(mount, []);

  return (
    <Stripe stripe={state.stripePromise}>
      <NewPaymentMethodForm ref={ref} {...props} />
    </Stripe>
  );
});

export default AddPaymentMethodForm;
