import FS from "./FetchService";

export default class ResourceService {
  static async loadIconList() {
    return await FS.get("/resources/icons");
  }

  static async uploadImage(img) {
    const form = new FormData();
    form.append("icon", img);

    return await FS.post("/resources/icons", form);
  }

  static async uploadLogo(name, img) {
    const form = new FormData();
    form.append(name, img);

    return await FS.post("/resources/logos", form);
  }

  static async deleteLogo(name) {
    return await FS.delete(`/resources/logos/${name}`);
  }
}
