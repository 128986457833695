import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import ResourceService from "services/ResourceService";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ButtonGroup,
  Input as _Input,
} from "@material-ui/core";

import Button from "./Button";
import LoadingContainer from "components/Loading/LoadingContainer";

const Input = styled(_Input)`
  display: none;
`;

const Icon = styled.img`
  max-width: 80px;
  max-height: 80px;
`;

const IconSelector = (props) => {
  const { src, value, name, onChange } = props;

  const [waiting, setWaiting] = useState(false);
  const [icons, setIcons] = useState();
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [imageToUpload, setUpload] = useState();
  const [iconsLoaded, setIconsLoaded] = useState(0);

  const loadIcons = useCallback(async () => {
    setIconsLoaded(0);

    if (Array.isArray(src)) {
      setIcons(src);
    } else {
      const icons = await ResourceService.loadIconList();
      setIcons(icons);
    }
  }, [src]);

  const handleLoadEnd = () => {
    setIconsLoaded((n) => ++n);
  };

  const uploadImage = async () => {
    setWaiting(true);
    const result = await ResourceService.uploadImage(imageToUpload);
    if (result?.icon) {
      handleSelect(result.icon)();
    }
    setWaiting(false);
  };

  const handleSelect = (icon) => () => {
    onChange && onChange({ target: { name, value: icon } });
    setSelectorOpen(false);
  };

  useEffect(() => {
    if (selectorOpen) {
      setUpload(null);
      setIcons({ loading: loadIcons() });
    }
  }, [selectorOpen, loadIcons]);

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={() => setSelectorOpen(true)}>
        {value && <Icon src={value} />}
      </Button>
      <Dialog open={selectorOpen}>
        {(!Array.isArray(icons) || waiting || icons.length > iconsLoaded) && (
          <LoadingContainer />
        )}
        <DialogTitle>Select Icon</DialogTitle>
        <DialogContent>
          {Array.isArray(icons) &&
            (icons.length > 0 ? (
              icons.map((icon, i) => (
                <Button key={i} variant="outlined" onClick={handleSelect(icon)}>
                  <Icon
                    src={icon}
                    onLoad={handleLoadEnd}
                    onError={handleLoadEnd}
                  />
                </Button>
              ))
            ) : (
              <span>
                No icons have been uploaded yet. Upload a new icon first.
              </span>
            ))}
        </DialogContent>
        <DialogTitle>New Icon</DialogTitle>
        <DialogContent>
          <ButtonGroup color="primary" variant="contained">
            <Button component="label" htmlFor="icon">
              <Input
                accept="image/*"
                id="icon"
                type="file"
                onChange={({ target: { files } }) => setUpload(files[0])}
              />
              {imageToUpload ? imageToUpload.name : "Choose Image"}
            </Button>
            <Button onClick={uploadImage} disabled={imageToUpload === null}>
              Upload
            </Button>
          </ButtonGroup>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setSelectorOpen(false)}>
            Cancel
          </Button>
          <Button color="secondary" onClick={handleSelect(null)}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default IconSelector;
