import React, { useEffect, useState, useContext } from "react";
import { compose } from "recompose";
import styled from "styled-components";

import Grid from "@material-ui/core/Grid";

import Panel from "components/AccountView/Input/Panel";
import Button from "components/Input/Button";
import Dropdown from "components/Input/Dropdown";
import TextInput from "components/Input/TextInput";
import LoadingContainer from "components/Loading/LoadingContainer";
import withTheme from "composers/withTheme";
import AccountService from "services/AccountService";
import RegionService from "services/RegionService";

import { AccountContext } from "context/AccountContext";

const Form = compose(withTheme)(styled.form`
  position: relative;
  width: 100%;
  justify-content: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "initial" : "space-between"};
  flex-direction: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "column" : "row"};
`);

const Info = compose(withTheme)(styled.div`
  width: 100%;
  padding-left: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "15px" : "30px"};
  padding-right: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "0" : "30px"};
  ${({ responsiveSize }) =>
    responsiveSize === "phone" && "margin-bottom: 22px;"}
`);

let originalValues = null;
const ManageProfileDetails = (props) => {
  const { profile, updateProfile } = useContext(AccountContext);

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    region: "",
    region_options: [],
    error: "",
    prevent_submit: false,
    editMode: false,
  });

  useEffect(() => {
    setState((orig) => ({ ...orig, ...profile }));
  }, [profile]);

  const loadRegions = async () => {
    try {
      const regions = await RegionService.getRegions();
      const region_options = regions.map(({ region, code, name }) => ({
        value: region,
        label: `${code ? `${code} - ` : ""}${name}`,
      }));

      setState((orig) => ({ ...orig, region_options: region_options }));
    } catch (e) {
      console.error("No data returned:", e.message);
    }
  };

  const mount = () => {
    loadRegions();

    return () => {};
  };
  useEffect(mount, []);

  const toggleEditMode = (e, value) => {
    if (value === undefined) {
      value = !state.editMode;
    }

    if (!!value) {
      originalValues = profile;
    }

    setState((orig) => ({ ...orig, editMode: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    update();
  };

  const handleCancel = (e) => {
    if (originalValues) {
      setState((orig) => ({ ...orig, ...originalValues, error: "" }));
    }

    toggleEditMode(e, false);
  };

  const update = async () => {
    if (!state.prevent_submit) {
      setState((orig) => ({ ...orig, prevent_submit: true }));
      try {
        const result = await AccountService.update(
          state.first_name,
          state.last_name,
          state.email,
          state.phone_number,
          state.region
        );

        if (result) {
          if (!result.errors) {
            updateProfile(result);
            setState((orig) => ({
              ...orig,
              editMode: false,
              error: "",
            }));
          } else {
            setState((orig) => ({ ...orig, error: result.errors }));
          }
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      } finally {
        setState((orig) => ({ ...orig, prevent_submit: false }));
      }
    }
  };

  const { responsiveSize } = props;

  return (
    <Panel invisible={responsiveSize === "phone" ? 1 : 0} style={props.style}>
      {state.prevent_submit && <LoadingContainer />}
      <Form onSubmit={handleSubmit}>
        <div>
          <h3>Profile</h3>
          <Info>
            <Grid
              container
              spacing={3}
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} md={6}>
                <TextInput
                  disabled={!state.editMode}
                  label="First Name"
                  type="text"
                  id="first_name"
                  name="first_name"
                  onChange={handleChange}
                  value={state.first_name}
                  error={state.error?.first_name}
                  errorMessage={state.error?.first_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  disabled={!state.editMode}
                  label="Last Name*"
                  type="text"
                  id="last_name"
                  name="last_name"
                  onChange={handleChange}
                  value={state.last_name}
                  error={state.error?.last_name}
                  errorMessage={state.error?.last_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  disabled={!state.editMode}
                  label="Phone Number"
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  onChange={handleChange}
                  value={state.phone_number}
                  error={state.error?.phone_number}
                  errorMessage={state.error?.phone_number}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  disabled={!state.editMode}
                  label="E-mail"
                  type="text"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={state.email}
                  error={state.error?.email}
                  errorMessage={state.error?.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Dropdown
                  disabled={!state.editMode}
                  nullable
                  nullValue="Unspecified"
                  label="Region"
                  id="region"
                  name="region"
                  onChange={handleChange}
                  value={state.region}
                  options={state.region_options}
                  error={state.error?.region}
                  errorMessage={state.error?.region}
                />
              </Grid>
            </Grid>
          </Info>
        </div>
        <Grid container justify={"flex-end"} style={{ margin: "2rem 0" }}>
          <Button
            onClick={state.editMode ? handleCancel : toggleEditMode}
            variant={state.editMode ? "outlined" : "contained"}
          >
            {state.editMode ? "Cancel" : "Edit"}
          </Button>
          {state.editMode && (
            <Button style={{ marginLeft: "10px" }} type="submit">
              Save
            </Button>
          )}
        </Grid>
      </Form>
    </Panel>
  );
};

export default compose(withTheme)(ManageProfileDetails);
