import styled from "styled-components";

const Field = styled.div`
  width: 100%;

  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }

  display: flex;
  justify-content: ${({ rightAlign }) => (rightAlign ? "flex-end" : "unset")};

  & > * {
    flex-basis: 1;
    flex-grow: 1;
    flex-shrink: 1;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

Field.displayName = "Field";

export default Field;
