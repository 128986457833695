import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

class FallbackSwitch extends React.Component {
  render() {
    const { children, fallback = "/", ..._props } = this.props;

    return (
      <Switch {..._props}>
        {children}
        <Route render={() => <Redirect to={fallback} />} />
      </Switch>
    );
  }
}

export default FallbackSwitch;
