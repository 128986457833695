import React, { useContext, useCallback } from "react";
import styled from "styled-components";

import { AppContext } from "context/AppContext";

const Logo = styled.img`
  max-width: 325px;
  width: 100%;
  user-select: none;
`;

export default (props) => {
  const { logos } = useContext(AppContext);

  const { src, type = "main_logo", subtitle } = props;

  const getLogo = useCallback(() => {
    if (src) return src;

    if (logos[type]) return logos[type];

    if (type === "main_logo") {
      return require("assets/general/TusitawiRotated.svg");
    } else {
      return require("assets/general/TusitawiNotRotated.svg");
    }
  }, [logos.main_logo, logos.nav_logo]);

  return <Logo {...props} subtitle={subtitle} src={getLogo()} />;
};
