import React, { useState, useEffect, useContext } from "react";

import Button from "components/Input/Button";
import TextInput from "components/Input/TextInput";

import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { ShopContext } from "context/ShopContext";

const CouponInput = (props) => {
  const {
    selectedBundle,
    appliedCoupon,
    handleApplyCoupon,
    errors: { coupon: error },
  } = useContext(ShopContext);

  const bundleID = selectedBundle?.product_id;

  const [coupon, setCoupon] = useState("");

  const handleChange = ({ target: { value } }) => {
    setCoupon(value);
  };

  const handleKeyDown = ({ keyCode, target: { value } }) => {
    if (keyCode === 13) {
      // Enter
      if (value !== appliedCoupon) {
        handleApplyCoupon(coupon);
      }
    }
  };

  // Clear the coupon if the bundle changes
  useEffect(() => {
    setCoupon("");
  }, [bundleID]);

  return (
    <TextInput
      label='Coupon Code'
      value={coupon}
      placeholder='Coupon Code'
      name='coupon'
      onChange={handleChange}
      icon={<LocalOfferIcon />}
      error={error}
      errorMessage={error}
      inputProps={{
        endAdornment: (
          <Button onClick={() => handleApplyCoupon(coupon)} disabled={!coupon}>
            Apply
          </Button>
        ),
        onKeyDown: handleKeyDown,
      }}
    />
  );
};

export default CouponInput;
