import isArray from "lodash/isArray";
import ServerErrorException from "exceptions/ServerErrorException";

/*
  Prompts dev users for IP address
  Non-dev users will generally access through 'https'
  Stores URL in localStorage for repeated access
*/
let URL = "";
if (process.env.NODE_ENV === "development") {
  if (localStorage.URL === undefined) {
    URL = prompt(
      "Please enter back end IP address. reprompt(); in the console will allow you to reinput an address"
    );
    localStorage.URL = (URL ? "http://" : "") + URL;
    window.location.reload();
  } else {
    URL = localStorage.URL;
  }
}

const API = `${URL}/api`;

/*
  Console function that reprompts user for IP address
  Refreshes page afterwards
*/
window.reprompt = function () {
  URL = prompt("Please enter back end IP address");
  localStorage.URL = (URL ? "http://" : "") + URL;
  window.location.reload();
};

/**
 * Every service that uses HTTP requests should use this service,
 * so that we can quickly / easily change what package we are using to
 * dispatch our HTTP requests. Currently we're using `fetch` API.
 *
 * If we wanted, we could change this to AXIOS. This also lets us quickly change where our environment is
 * pointing to, were that a feature we would want.
 */
export default class FetchService {
  /**
   * Images are stored with relative paths, (ex. '/images/l4h/one.jpg').
   *
   * Our API can change though during development, so I use this to
   * ensure that we're pointing it to the right absolute URL.
   */
  static resolveAssetUrl(url) {
    if (!url) {
      return;
    }

    return `${URL}${url}`;
  }

  /**
   * Every request that isn't logging in needs the authorization token. Might as well
   * make it a reusable method.
   */
  static getHeaders() {
    const headers = new Headers();
    headers.append("accept", "application/json");

    const token = sessionStorage.getItem("token");
    if (token) {
      headers.append("authorization", `Bearer ${token}`);
    }

    return headers;
  }

  static async fetch(url, opts) {
    const result = await fetch(url, opts);
    if (result.status === 500) {
      let consoleMessage = "500 error;";
      consoleMessage += ` URL: ${url};`;
      consoleMessage += ` Method: ${opts.method || "GET"};`;
      if (opts.body) {
        consoleMessage += ` Body: ${opts.body};`;
      }

      console.error(consoleMessage);
      throw new ServerErrorException(opts.body || "");
    }

    return result;
  }

  static async get(url) {
    const headers = this.getHeaders();

    return await (
      await this.fetch(`${API}${url}`, { method: "GET", headers })
    ).json();
  }

  /**
   * @param {Object} options.header A function that is invoked with a Headers instance, allowing you to append additional headers to the request.
   * @param {Object} options.response Change the default response behaviour (json) to text / anything else that a typical response has.
   */
  static async post(url, body, options = {}) {
    const { response = "json" } = options;
    const headers = this.getHeaders();
    if (options.header) {
      options.header(headers);
    }

    return await (
      await this.fetch(`${API}${url}`, {
        method: "POST",
        headers,
        body,
      })
    )[response]();
  }

  static async patch(url, body) {
    const headers = this.getHeaders();
    if (body instanceof FormData) {
      body.append("_method", "PATCH");
    } else {
      headers.append("Content-Type", "application/json");
    }

    return await (
      await this.fetch(`${API}${url}`, {
        method: "POST",
        headers,
        body,
      })
    ).json();
  }

  static async delete(url, body, options = {}) {
    const headers = this.getHeaders();
    if (options.header) {
      options.header(headers);
    }

    return await (
      await this.fetch(`${API}${url}`, {
        method: "DELETE",
        body,
        headers,
      })
    ).json();
  }

  /**
   * Iterates through the keys of an object, and turns their
   * key name into a form field, and the key's value into
   * the forms' fields' value.
   *
   * Supports arrays, and regular values, as well as Files.
   */
  static jsonToForm(obj) {
    // Convert a regular object into a formdata for posting.
    // Makes the backend implementation a wee bit easier.
    let form = Object.keys(obj).reduce((formdata, key) => {
      if (isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          const val = obj[key][i];
          formdata.append(`${key}[]`, val);
        }
      } else {
        formdata.append(key, obj[key]);
      }

      return formdata;
    }, new FormData());

    return form;
  }
}
