import React, { useContext } from "react";
import { AccountContext } from "context/AccountContext";
import styled from "styled-components";

import AccountPanel from "components/AccountView/Input/Panel";
import LoginPanel from "components/LoginView/Input/Panel";
import LoadingContainer from "components/Loading/LoadingContainer";
import { compose } from "recompose";
import withTheme from "composers/withTheme";

const compare = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

const Number = styled.span`
  position: absolute;
  left: 12px;
  top: 15px;
`;

const Panel = compose(withTheme)(styled.div`
  ${({ responsiveSize }) =>
    responsiveSize === "phone"
      ? `
      padding-left: 13px;
      padding-right: 13px;
    `
      : `
      padding-left: 40px;
      padding-right: 40px;
    `}

  ${({ centered }) =>
    centered &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`);

const ShopStepper = (props) => {
  const { isLoggedIn } = useContext(AccountContext);

  const { step, currentStep, style, pre, post, children, loading, ..._props } =
    props;

  const NumberIcon = require(`mdi-material-ui/Numeric${step}Circle`).default;

  const position = compare(parseInt(currentStep), parseInt(step));

  return (
    <Panel
      as={isLoggedIn() ? AccountPanel : LoginPanel}
      {..._props}
      style={{ ...style, transition: "height 1s" }}
    >
      {loading && <LoadingContainer />}
      <Number centered={position < 0}>
        <NumberIcon color="primary" />
      </Number>
      {(() => {
        switch (position) {
          case -1:
            return pre || children;
          case 0:
            return children;
          case 1:
            return post || children;
          default:
            return null;
        }
      })()}
    </Panel>
  );
};

export default ShopStepper;
