import FS from "./FetchService";

export default class RegionService {
  static async getRegions() {
    return await FS.get("/regions");
  }

  static async addRegion(name, code) {
    const form = new FormData();
    form.append("name", name || "");
    form.append("code", code || "");

    return await FS.post(`/regions`, form);
  }

  static async saveRegion(region_id, name, code) {
    const form = new FormData();
    form.append("name", name || "");
    form.append("code", code || "");

    return await FS.patch(`/regions/${region_id}`, form);
  }

  static async deleteRegion(region_id) {
    return await FS.delete(`/regions/${region_id}`);
  }

  static async deleteAll() {
    return await FS.delete(`/regions`);
  }
}
